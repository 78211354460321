import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import * as fabric from 'fabric' // v6
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import {
  Delete,
  PentagonOutlined,
  RectangleOutlined,
} from '@mui/icons-material'

import { v4 as uuidv4 } from 'uuid'
import { ReadOnlyLeftPanel } from '../map/MarkerNavigator'
import MarkerEditor from '../map/MarkerEditor'
import { feature } from '@turf/helpers'
// import { fetchIndividual } from '../viewer/viewerSlice'
// import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
// import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { useHistory } from 'react-router-dom'
import {
  generateLinkForObject,
  generatePublicLinkForObject,
} from 'src/modules/app/links'
import theme from '../ui/theme'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

import { makeStyles } from '@mui/styles'
import { PublicContext } from '../public/contexts'

const debug = false

const themePalette: any = theme.palette

const SAVE_LINKS_ON_EDIT_DISMOUNT = false
// SAVE_LINKS_ON_EDIT_DISMOUNT doesn't work - new item isn't saved. There's an error from onToolboxClosed()
// which might be conflicting by trying to save twice at the same time.
// Also the Fabric canvas is not disposed of because Edit doesn't know that the main close button has been pressed.

interface WeAreLink {
  id: string // id of this link
  instanceId?: string // id of the target
  instanceType?: string // returned by the API calling instance_types.get_instance_type
  display?: string // this should probably be under target but has been returned at this level from the API since May 2022 - the target's __str__
  target?: {
    display?: string // prefer this as the parent display is just the target's __str__
    photo?: {
      id: string
      fileMedium?: string
      fileThumbnail?: string
    }
  }
}

interface SvgWeAreLink extends WeAreLink {
  annotationsSvg?: string
}

enum LinksChangedReason {
  TOOLBOX_CLOSED,
  SHAPE_DELETED,
}

const FabricToolbox = ({
  activeTool,
  onToolChanged,
}: {
  activeTool: string | null
  onToolChanged?: CallableFunction
}) => {
  if (debug)
    console.debug(`FabricToolbox: rendering... activeTool: ${activeTool}`)

  const useStyles = makeStyles(theme => ({
    eachButton: {
      height: '29px',
      color: 'black',
      stroke: 'black',
      verticalAlign: 'middle',
      width: '100%',
      padding: '6px',
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: themePalette.lightPurple.main,
      },

      '&:hover': {
        backgroundColor: '#C7C2C8', // a custom darker grey copied from maps toolbox
      },
    },
    eachButtonImage: {
      width: '100%',
      height: '100%',
    },
  }))

  const classes = useStyles()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newActiveTool: string | null
  ) => {
    if (debug)
      console.debug(
        `AnnotatedImageFabric.FabricToolbox.handleChange(): called with newActiveTool`,
        newActiveTool
      )

    if (onToolChanged) {
      onToolChanged(newActiveTool)
    }
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '29px', // copied from Mapbox controls
        boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 0,
        zIndex: 2, // on top of the crop/edit buttons
      }}
    >
      <>
        <ToggleButtonGroup
          value={activeTool}
          size="small"
          aria-label="Drawing tool buttons"
          orientation="vertical"
          exclusive
          onChange={handleChange}
          fullWidth={true}
        >
          <ToggleButton
            className={classes.eachButton}
            value="delete"
            sx={{
              padding: '2px', // let the bin be a bit bigger
            }}
          >
            {' '}
            <Delete
              className={classes.eachButtonImage}
              sx={{
                stroke: 'initial', //disable the thick drawing as it removes the gap below the bin lid
              }}
            />
          </ToggleButton>

          <ToggleButton
            className={classes.eachButton}
            value="poly"
            //styles={{      paddingLeft: '6.5px', }} //the polygon has a tiny sliver of transparency on its right
          >
            {' '}
            <PentagonOutlined className={classes.eachButtonImage} />
          </ToggleButton>

          <ToggleButton
            //sx={{ height: '29px', color: 'black', stroke: 'black' }}
            className={classes.eachButton}
            value="rect"
          >
            {' '}
            <RectangleOutlined className={classes.eachButtonImage} />
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    </Box>
  )
}

const shapePropsViewOnly = {
  opacity: 0,
  selectable: false,
}

const shapePropsViewOnlyHovered = {
  fill: themePalette.lightPurple.main,
  opacity: 0.5,
  strokeWidth: 2,
  stroke: 'white',
}

const shapePropsEditing = {
  selectable: true,
  stroke: 'black',
  strokeWidth: 1,
  strokeUniform: true, // otherwise the border gets wider when the shape is scaled
  transparentCorners: false,
  cornerColor: 'purple',
  cornerStrokeUniform: true,
  fill: themePalette.lightPurple.main,
  opacity: 0.5,
}

const shapePropsDrawingNew = {
  fill: 'pink',
  strokeWidth: 1,
  stroke: 'red',
  opacity: 0.5,
  selectable: false,
}

const getUnzoomedImageToViewportRatios = (
  canvasElement: HTMLElement,
  originalImageWidthPx: number,
  originalImageHeightPx: number
) => {
  const viewportWidth = canvasElement.offsetWidth // the canvas width, filling most of the browser window
  const unzoomedImageToViewportRatioWidth = viewportWidth / originalImageWidthPx

  const viewportHeight = canvasElement.offsetHeight // the canvas height, filling most of the browser window

  if (debug)
    console.debug(
      `getUnzoomedImageToViewportRatio(): viewportWidth/Height: ${viewportWidth} x ${viewportHeight}`
    )

  if (debug)
    console.debug(
      `getUnzoomedImageToViewportRatio(): image Width/Height: ${originalImageWidthPx} x ${originalImageHeightPx}`
    )

  const unzoomedImageToViewportRatioHeight =
    viewportHeight / originalImageHeightPx

  if (debug)
    console.debug(
      `getUnzoomedImageToViewportRatio(): ratios: ${unzoomedImageToViewportRatioWidth} x ${unzoomedImageToViewportRatioHeight}`
    )

  return [unzoomedImageToViewportRatioWidth, unzoomedImageToViewportRatioHeight]
}

const panState = {
  lastPosX: null as number | null,
  lastPosY: null as number | null,
  isDragging: false,
}

const initPan = (
  canvas: fabric.Canvas | undefined,
  fabricEvent: fabric.TPointerEventInfo
) => {
  //const debug = true
  if (debug) console.debug(`initPan(): setting panState...`, fabricEvent)

  panState.isDragging = true
  panState.lastPosX = fabricEvent.viewportPoint.x
  panState.lastPosY = fabricEvent.viewportPoint.y

  if (canvas?.elements?.upper?.el.style) {
    if (debug) console.debug(`initPan(): setting mouse cursor to 'grabbing'`)
    canvas.elements.upper.el.style.cursor = 'grabbing'
  }
}

function pan(canvas: fabric.Canvas, fabricEvent: fabric.TPointerEventInfo) {
  if (!panState.isDragging) {
    return
  }

  const x = fabricEvent.viewportPoint.x
  const y = fabricEvent.viewportPoint.y

  // if (debug) console.debug(
  //   `on mouse:move: creationTool.isDragging is true, e.clientX: ${e.clientX}, e.clientY: ${e.clientY}, fabricEvent:`,
  //   fabricEvent
  // )
  const vpt = canvas.viewportTransform
  //if (debug) console.debug(`on mouse:move: vpt:`, vpt)
  if (!vpt[4]) {
    vpt[4] = 0
  }
  if (!vpt[5]) {
    vpt[5] = 0
  }

  const onscreenDiffX = x - (panState.lastPosX ?? 0)
  const onscreenDiffY = y - (panState.lastPosY ?? 0)

  const imageMovedPixelsX = onscreenDiffX
  const imageMovedPixelsY = onscreenDiffY

  if (debug)
    console.debug(
      `pan(): viewportPoint: ${fabricEvent.viewportPoint.x} x ${fabricEvent.viewportPoint.y} onscreenDiff: ${onscreenDiffX} x ${onscreenDiffY}, viewportTransform offsets: ${vpt[4]} x ${vpt[5]}, imageMovedPixels: ${imageMovedPixelsX} x ${imageMovedPixelsY}`
    )
  vpt[4] += imageMovedPixelsX
  vpt[5] += imageMovedPixelsY
  canvas.requestRenderAll()
  panState.lastPosX = x
  panState.lastPosY = y
}

const cancelPan = () => {
  panState.isDragging = false
}

interface FabricJSCanvasProps {
  file: string
  originalImageWidthPx: number
  originalImageHeightPx: number
  links: Array<SvgWeAreLink>
  calledByCanvasWhenShapeSelected: CallableFunction
  calledByCanvasWhenShapeClickedInViewMode: CallableFunction
  updateLocalStateAndSaveLinks?: CallableFunction
  onFabricObjectsCreated?: (fabricObjects: fabric.Object[]) => void
  selectedObjectWeAreId?: string
  isEditMode?: boolean
  setIsEditMode: CallableFunction
  onMouseMove?: CallableFunction
}
const FabricJSCanvas = ({
  file,
  originalImageWidthPx,
  originalImageHeightPx,
  links,
  calledByCanvasWhenShapeSelected,
  calledByCanvasWhenShapeClickedInViewMode,
  updateLocalStateAndSaveLinks, // passed to EditMode
  onFabricObjectsCreated,
  selectedObjectWeAreId,
  isEditMode,
  setIsEditMode,
  onMouseMove,
}: // TODO add calledByCanvasWithSerialisedDrawingsWhenToolboxClosed
FabricJSCanvasProps) => {
  const canvasElRef = useRef<HTMLCanvasElement>(null)
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null)

  const [fabricCanvas, setFabricCanvasImpl] = useState<fabric.Canvas>()
  const setFabricCanvas = (newCanvas: fabric.Canvas) => {
    if (debug)
      console.debug(
        `FabricJSCanvas.setFabricCanvas(): called with newCanvas:`,
        newCanvas
      )
    setFabricCanvasImpl(newCanvas)
  }

  console.debug(
    `FabricJSCanvas(): rendering... isEditMode: ${isEditMode}, fabricCanvasRef.current: ${fabricCanvasRef.current}, fabricCanvas (localstate): ${fabricCanvas}, file: ${file}`
  )

  const canvasOnMouseOverViewOnly = useCallback(
    (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent> //& OutEvent
    ) => {
      //const debug = true
      if (!e.target) {
        return
      }
      if (debug)
        console.debug(
          `FabricJSCanvas.canvasOnMouseOverViewOnly(): called with event:`,
          e
        )

      if (e.target.canvas !== fabricCanvasRef.current) {
        console.error(
          `FabricJSCanvas.canvasOnMouseOverViewOnly(): e.target.canvas is not the same as fabricCanvasRef.current!`,
          e.target.canvas,
          fabricCanvasRef.current
        )
      }

      calledByCanvasWhenShapeSelected(e.target) // calls setCurrentSidebarShapeIdx()

      e.target.set(shapePropsViewOnlyHovered)
      if (debug)
        console.debug(
          `FabricJSCanvas.canvasOnMouseOverViewOnly(): applied shapePropsViewOnlyHovered to shape weare_id '${e.target.get(
            'weare_id'
          )}'`,
          shapePropsViewOnlyHovered
        )
    },
    [calledByCanvasWhenShapeSelected] // calledByCanvasWhenShapeSelected is a prop
  )

  const canvasOnMouseOutViewOnly = useCallback(
    (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent> //& OutEvent
    ) => {
      if (!e.target) {
        return
      }
      if (debug)
        console.debug(
          `FabricJSCanvas.canvasOnMouseOutViewOnly(): called with event:`,
          e
        )

      calledByCanvasWhenShapeSelected(null) // calls setCurrentSidebarShapeIdx()
      e.target.set(shapePropsViewOnly)
      fabricCanvasRef.current?.renderAll()
    },
    [calledByCanvasWhenShapeSelected] //calledByCanvasWhenShapeSelected is a prop
  )

  // this is redefined on every props change because calledByCanvasWhenShapeClickedInViewMode comes from a prop
  const canvasOnMouseDownViewMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      //const debug = true
      if (debug)
        console.debug(
          `canvasOnMouseDownViewMode(): called with fabricEvent:`,
          fabricEvent
        )
      if (fabricEvent.target) {
        // we actually only want to do this when the user has 'clicked' on the shape, but here they might
        // actually be intending to start dragging the canvas about.
        // But fabric doesn't have a click event and writing our own to determine a click from a mouseDown/mouseUp pair
        // is too much of a faff to do right now as it has to consider timings and mouse positions with leeway
        // for shaky hands.
        // So for now if the user wants to drag the canvas about they'll have to drag on the canvas not a shape.
        const eventHandled = calledByCanvasWhenShapeClickedInViewMode(
          fabricEvent.target
        ) // this might navigate to the link's page
        if (!eventHandled) {
          initPan(fabricCanvas, fabricEvent)
        }
      } else {
        initPan(fabricCanvas, fabricEvent)
      }
    },
    [calledByCanvasWhenShapeClickedInViewMode, fabricCanvas] // calledByCanvasWhenShapeClickedInViewMode is a prop, fabricCanvas is local state
  )

  const canvasOnMouseMoveViewMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      if (
        panState.isDragging &&
        canvasElRef.current &&
        fabricCanvasRef.current
      ) {
        pan(
          fabricCanvasRef.current,
          fabricEvent
          //canvasElRef.current,
          //originalImageWidthPx
        )
      }

      if (onMouseMove) {
        //passed in as a prop, this lets AnnotatedImage decide which corner to place the popup in
        onMouseMove(fabricEvent)
      }
    },
    [onMouseMove] //onMouseMove is a prop
  )

  const canvasOnMouseUpViewMode = (
    fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>
  ) => {
    cancelPan()
  }

  // it's difficult to deregister the event listeners added to the fabric canvas because
  // we need the original handler function to deregister it and it might have changed
  // due to being a useCallback
  const deregisterViewModeCanvasMouseEventListeners = useRef<
    CallableFunction | undefined
  >()

  //TODO perhaps move setCanvasEditable() into EditMode?
  const setCanvasEditable = useCallback(
    (canvas: fabric.Canvas, editable: boolean) => {
      //const debug = true
      if (debug)
        console.debug(
          `FabricJSCanvas.setCanvasEditable(${editable}): called with editable: ${editable}, canvas:`,
          canvas
        )

      //enable or disable the ability to select canvas items
      canvas.selection = editable
      // the style sets selectable on each canvas item

      const shapeProps = editable ? shapePropsEditing : shapePropsViewOnly

      if (debug)
        console.debug(
          `FabricJSCanvas.setCanvasEditable(${editable}): setting all shapes' props to:`,
          shapeProps
        )

      canvas.forEachObject(fabricObject => {
        fabricObject.set(shapeProps)

        if (debug)
          console.debug(
            `setCanvasEditable(${editable}): set props on fabricObject:`,
            shapeProps,
            fabricObject
          )
      })

      //TODO set different props for the currently selected item

      canvas.hoverCursor = 'pointer'

      if (debug)
        console.debug(
          `FabricJSCanvas.setCanvasEditable(${editable}): set canvas default hover mouse cursor to '${canvas.hoverCursor}'`
        )

      if (!editable) {
        //this fn can be called by useEffect multiple times so always de-register the
        //event listeners otherwise they can be registered multiple times
        if (deregisterViewModeCanvasMouseEventListeners.current) {
          deregisterViewModeCanvasMouseEventListeners.current()
        }

        if (debug)
          console.debug(
            `FabricJSCanvas.setCanvasEditable(${editable}): editable is: ${editable}, before registering mouse event listeners to canvas:`,
            { ...canvas['__eventListeners'] }
          )
        deregisterViewModeCanvasMouseEventListeners.current = canvas.on({
          'mouse:over': canvasOnMouseOverViewOnly,
          'mouse:out': canvasOnMouseOutViewOnly,
          'mouse:down': canvasOnMouseDownViewMode,
          'mouse:up': canvasOnMouseUpViewMode,
          'mouse:move': canvasOnMouseMoveViewMode,
        })
        if (debug)
          console.debug(
            `FabricJSCanvas.setCanvasEditable(${editable}): registered mouse event listeners to canvas:`,
            { ...canvas['__eventListeners'] }
          )
      } else {
        if (deregisterViewModeCanvasMouseEventListeners.current) {
          deregisterViewModeCanvasMouseEventListeners.current()
          deregisterViewModeCanvasMouseEventListeners.current = undefined
        }
      }

      canvas.requestRenderAll()
      if (debug)
        console.debug(
          `FabricJSCanvas.setCanvasEditable(${editable}): set canvas.selection to ${editable} and canvas.forEachObject() to set selectable to ${editable}.`
        )
    },
    [
      canvasOnMouseDownViewMode,
      canvasOnMouseMoveViewMode, // this useCallback is recreated when selectedObjectWeAreId changes
      canvasOnMouseOutViewOnly,
      canvasOnMouseOverViewOnly,
    ]
  )

  // only want the useEffect below to run when isEditMode changes, not any of its other deps
  const lastIsEditableStateRef = useRef<boolean>()

  // observe isEditMode and switch the canvas between view-only and editable by calling setCanvasEditable()
  useEffect(() => {
    //const debug = true
    if (debug)
      console.debug(
        `FabricJSCanvas.useEffect([isEditMode]): isEditMode is ${isEditMode}...`
      )

    if (!fabricCanvasRef.current) {
      if (debug)
        console.debug(
          `FabricJSCanvas.useEffect([isEditMode]): fabricCanvasRef not set, doing nothing.`
        )
      return
    }

    const setTo = isEditMode ?? false

    // I'm sure this is dirty!
    // setCanvasEditable changes because useCallbacks it calls change, but that doesn't
    // mean we want to re-run it.
    if (setTo !== lastIsEditableStateRef.current) {
      if (debug)
        console.debug(
          `FabricJSCanvas.useEffect([isEditMode]): calling setCanvasEditable(${setTo})...`
        )
      lastIsEditableStateRef.current = setTo
      setCanvasEditable(fabricCanvasRef.current, setTo)
    }
  }, [isEditMode, setCanvasEditable])

  /**
   * called on FabricJSCanvas dismount to clean up
   *
   * This needs to know the up-to-date local state 'isEditMode', but:
   *  - if we use it without putting it in the dependencies array it is out-of-date
   *  - if we include it in the dependencies array then the dismount fn runs whenever it changes
   * So instead we do a dirty hack - call setIsEditMode() with a callback fn just to get the up-to-date value.
   *
   */
  useEffect(() => {
    return () => {
      // if (debug) {
      //   setIsEditMode((existingIsEditMode: boolean) => {
      //     console.debug(
      //       `FabricJSCanvas.useEffect([canvasEl]).dismount: FabricJSCanvas component dismounting - isEditMode: ${isEditMode}, existingIsEditMode: ${existingIsEditMode}, canvasElRef now:`,
      //       canvasElRef
      //     )
      //     return existingIsEditMode
      //   })
      // }

      if (fabricCanvasRef.current) {
        if (SAVE_LINKS_ON_EDIT_DISMOUNT) {
          // want to clean up nicely but if we're edit mode on dismount we want to let the EditMode's
          // dismount fn run to save the latest SVG data. If we dispose of the canvas here its contents
          // won't be available for saving.

          // local state var 'isEditMode' will be out of date as we don't have it listed as a dependency (intentional, see comment)
          // so call setIsEditMode() with a callback to get the latest value
          setIsEditMode((existingIsEditMode: boolean) => {
            if (debug)
              console.debug(
                `FabricJSCanvas.useEffect([canvasEl]).dismount: FabricJSCanvas component dismounting - existingIsEditMode: ${existingIsEditMode}, canvasElRef now:`,
                canvasElRef
              )

            if (!existingIsEditMode) {
              if (debug)
                console.debug(
                  `FabricJSCanvas.useEffect([canvasEl]).dismount: disposing of canvas...`
                )
              fabricCanvasRef.current?.dispose()
              //TODO dispose of Fabric canvas in edit mode
            } else {
              if (debug)
                console.debug(
                  `FabricJSCanvas.useEffect([canvasEl]).dismount: not disposing of canvas because isEditMode is ${existingIsEditMode}`
                )
            }
            console.debug(
              `FabricJSCanvas.useEffect([canvasEl]).dismount: setting fabricCanvasRef.current to null`
            )

            // but look out! Error: fabric: Trying to initialize a canvas that has already been initialized. Did you forget to dispose the canvas?
            fabricCanvasRef.current = null
            return existingIsEditMode // don't want to change isEditMode
          })
        } else {
          fabricCanvasRef.current?.dispose()
          fabricCanvasRef.current = null
        }
      }
      // if (callMeWithCanvas) {
      //   callMeWithCanvas(null)
      // }
    }
    // don't want any deps here, we only want the returned fn to execute
    // when the component dismounts, not when any values change.
    // It calls setIsEditMode() with a callback fn to get the latest value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ZOOM_TO_FIT = true
  const CENTER_VIEWPORT_AROUND_ZOOMED_IMAGE_WIDTH = true
  const BACKGROUND_IMAGE = true

  const createFabricCanvas = useCallback(
    async (canvasElement: HTMLCanvasElement, links: Array<SvgWeAreLink>) => {
      //const debug = true
      if (debug) console.debug(`FabricJSCanvas.createFabricCanvas(): called`)

      const canvasOptions = {
        uniformScaling: false,
        svgViewportTransformation: false,
      }
      // uniformScaling (default true): When true, objects can be transformed by one side (unproportionally) when dragged on the corners that normally would not do that.

      if (!canvasElement.parentElement) {
        console.error(
          `FabricJSCanvas.createFabricCanvas(): cannot get canvas element's parent`
        )
        return
      }

      // the canvas' container - fabricJSCanvasContainer defined in AnnotatedImage JSX - is set to size 100%/100%.
      // Grab its size in pixels so the canvas can be set to that. Don't use the image size because if it's larger
      // than the viewport (screen) it'll need to be scrolled around regardless of image zoom, or if it's smaller
      // the image doesn't use the available screen space and zooming it with CSS causes it to look blocky
      // when zoomed out.
      //TODO call canvas.setDimensions() if browser window size changes
      const initialCanvasContainerWidthPx =
        canvasElement.parentElement.offsetWidth
      const initialCanvasContainerHeightPx =
        canvasElement.parentElement.offsetHeight

      let canvas
      try {
        canvas = new fabric.Canvas(canvasElement, canvasOptions)
      } catch (err) {
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): new fabric.Canvas() errored, probably already exists, trying again passing in element id. err:`,
          err
        )
        canvas = new fabric.Canvas('annotatedImageFabricCanvasId')
        if (canvas) {
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): got canvas using element id.`
          )
        } else {
          console.error(
            `FabricJSCanvas.createFabricCanvas(): Error creating new canvas and then failed to get canvas using element id. err:`,
            err
          )
        }
      }

      fabricCanvasRef.current = canvas // do this ASAP because it stops this fn executing multiple times
      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): set fabricCanvasRef.current.`
        )

      // setting canvas css size to 100%/100% will stretch the image
      // setting backing dimensions to that of the image and CSS dimensions to 100% and 'auto'
      // works but on smaller images the backing pixels are too large so that zooming in
      // to a smaller image makes it look very blocky
      canvas.setDimensions({
        width: initialCanvasContainerWidthPx,
        height: initialCanvasContainerHeightPx,
      })
      //TODO resize the Fabric canvas if the window resizes

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): initialCanvasContainerWidthPx x initialCanvasContainerHeightPx: ${initialCanvasContainerWidthPx} x ${initialCanvasContainerHeightPx}`
        )

      if (ZOOM_TO_FIT) {
        const unzoomedImageToViewportRatios = getUnzoomedImageToViewportRatios(
          canvasElement,
          originalImageWidthPx,
          originalImageHeightPx
        )

        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): unzoomedImageToViewportRatio: ${unzoomedImageToViewportRatios}`
          )
        const initialZoom = Math.min(
          unzoomedImageToViewportRatios[0],
          unzoomedImageToViewportRatios[1]
        )

        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): initialZoom: ${initialZoom}`
          )

        if (initialZoom) {
          if (debug)
            console.debug(
              `FabricJSCanvas.createFabricCanvas(): initialZoom is truthy... calling canvas.setZoom(${initialZoom})...`
            )
          canvas.setZoom(initialZoom)

          if (CENTER_VIEWPORT_AROUND_ZOOMED_IMAGE_WIDTH) {
            const zoomedImageWidth = originalImageWidthPx * initialZoom

            if (zoomedImageWidth < initialCanvasContainerWidthPx) {
              const horizontalSpaceAroundImage =
                initialCanvasContainerWidthPx - zoomedImageWidth

              const leftMargin = horizontalSpaceAroundImage / 2

              if (debug)
                console.debug(
                  `FabricJSCanvas.createFabricCanvas(): calculating left margin - originalImageWidthPx: ${originalImageWidthPx}, initialZoom: ${initialZoom}, zoomedImageWidth: ${zoomedImageWidth}, initialCanvasContainerWidthPx: ${initialCanvasContainerWidthPx}, horizontalSpaceAroundImage: ${horizontalSpaceAroundImage}, leftMargin: ${leftMargin}`
                )

              canvas.viewportTransform[4] = leftMargin // the offset is in backing pixels before stretched by CSS to fill the browser area
              // if (debug) console.debug(
              //   `FabricJSCanvas.useEffect(): set x offset - canvas.viewportTransform[4] to (initialCanvasContainerWidthPx - zoomedImageWidthViewport) / 2 - (${initialCanvasContainerWidthPx} - ${zoomedImageWidthViewport})/2 : ${canvas.viewportTransform[4]}`
              // )
            } else {
              const zoomedImageHeight = originalImageHeightPx * initialZoom

              if (zoomedImageHeight < initialCanvasContainerHeightPx) {
                const verticalSpaceAroundImage =
                  initialCanvasContainerHeightPx - zoomedImageHeight

                const topMargin = verticalSpaceAroundImage / 2

                if (debug)
                  console.debug(
                    `FabricJSCanvas.useEffect(): calculating top margin - originalImageHeightPx: ${originalImageHeightPx}, initialZoom: ${initialZoom}, zoomedImageHeight: ${zoomedImageHeight}, initialCanvasContainerHeightPx: ${initialCanvasContainerHeightPx}, verticalSpaceAroundImage: ${verticalSpaceAroundImage}, topMargin: ${topMargin}`
                  )

                canvas.viewportTransform[5] = topMargin // the offset is in backing pixels before stretched by CSS to fill the browser area
              }
            }
          }
        }
      }

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): after zoom section. Setting canvas.defaultCursor to 'grab'`
        )

      canvas.defaultCursor = 'grab'

      const maxZoom = 20
      const minZoom = 0.1

      const setBackgroundImage = async (
        canvas: fabric.Canvas,
        file: string
      ) => {
        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas().setBackgroundImage(): awaiting  await fabric.FabricImage.fromURL('${file}')...`
          )
        const fi = await fabric.FabricImage.fromURL(file)
        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas().setBackgroundImage(): created FabricImage from url '${file}`,
            fi
          )
        fi.excludeFromExport = true
        canvas.backgroundImage = fi
      }

      if (BACKGROUND_IMAGE) {
        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): awaiting setBackgroundImage(file: '${file}')...`
          )
        await setBackgroundImage(canvas, file)
        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): setBackgroundImage() returned.`
          )
      }

      //called below for each link
      const addLinkSvgToCanvas = async (
        canvas: fabric.Canvas,
        linkId: string,
        annotationsSvg: string
      ) => {
        const loadedSVG = await fabric.loadSVGFromString(annotationsSvg)

        console.debug(
          `FabricJSCanvas.createFabricCanvas().addLinkSvgToCanvas(): loaded svg:`,
          annotationsSvg
        )

        loadedSVG.objects.forEach(fabricObject => {
          if (fabricObject) {
            fabricObject.set('fill', '#ff0000')
            fabricObject.set('opacity', 1)

            fabricObject.set('weare_id', linkId)

            console.debug(
              `FabricJSCanvas.createFabricCanvas().addLinkSvgToCanvas(): adding shape to canvas:`,
              fabricObject
            )
            canvas.add(fabricObject)

            //createFeature(o)
          }
        })

        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvas(): annotationsSvg converted to fabric objects:`,
            loadedSVG.objects
          )

        if (onFabricObjectsCreated && loadedSVG.objects) {
          onFabricObjectsCreated(loadedSVG.objects as fabric.Object[])
        }
      } // end populateCanvasFromSvg()

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): adding annotations from links to canvas...`,
          links
        )
      if (links) {
        //        await links.forEach(async link => {
        for await (const link of links) {
          if (link.annotationsSvg) {
            if (debug)
              console.debug(
                `FabricJSCanvas.createFabricCanvas(): annotationSvg prop set on link id '${link.id}', awaiting addLinkSvgToCanvas() to add link to canvas...`,
                link
              )
            await addLinkSvgToCanvas(canvas, link.id, link.annotationsSvg)

            if (debug)
              console.debug(
                `FabricJSCanvas.createFabricCanvas(): await addLinkSvgToCanvas() done for link:`,
                link
              )
          }
        }
      } // end if links
      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): added shapes from links to canvas.`
        )

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): calling setCanvasEditable(false)...`
        )

      setCanvasEditable(canvas, false)

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): setCanvasEditable(false) returned.`
        )

      canvas.on('mouse:wheel', function (fabricEvent) {
        if (!canvasElement) {
          return
        }
        const delta = fabricEvent.e.deltaY

        //const imageBackingToViewportRatio

        const existingZoom = canvas.getZoom()

        const newZoom = Math.max(
          Math.min(existingZoom * 0.999 ** delta, maxZoom),
          minZoom
        )

        //const scenePoint = canvas.getScenePoint(fabricEvent.e)
        const viewportPoint = canvas.getViewportPoint(fabricEvent.e)

        canvas.zoomToPoint(
          //{ x: fabricEvent.e.offsetX, y: fabricEvent.e.offsetY },
          //new fabric.Point(fabricEvent.e.offsetX, fabricEvent.e.offsetY),
          //new fabric.Point(mouseOverImagePixelX, mouseOverImagePixelY),
          //scenePoint,
          viewportPoint,
          newZoom
        )

        // don't want to change canvas size as image is zoomed because
        // that doesn't allow extra viewport around the edges to allow
        // panning
        // canvas.setDimensions({
        //   width: originalImageWidthPx * newZoom,
        //   height: originalImageHeightPx * newZoom,
        // })

        if (debug)
          console.debug(
            `on mouse:wheel: after zooming, set zoom to ${newZoom}, fabricEvent.e.offset: ${fabricEvent.e.offsetX} x ${fabricEvent.e.offsetY}, viewportPoint: ${viewportPoint}, imagePanOffset now: ${canvas.viewportTransform[4]} x ${canvas.viewportTransform[5]}`
          )
        fabricEvent.e.preventDefault()
        fabricEvent.e.stopPropagation()

        canvas.requestRenderAll()
      })

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): calling canvas.requestRenderAll()...`
        )
      canvas.requestRenderAll()
      //canvas.renderAll()

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvas(): setting local state fabricCanvas...`
        )
      setFabricCanvas(canvas)

      if (debug) console.debug(`FabricJSCanvas.createFabricCanvas(): done.`)
    },
    [
      BACKGROUND_IMAGE,
      CENTER_VIEWPORT_AROUND_ZOOMED_IMAGE_WIDTH,
      ZOOM_TO_FIT,
      file,
      onFabricObjectsCreated,
      originalImageHeightPx,
      originalImageWidthPx,
      setCanvasEditable,
    ]
  ) // end createFabricCanvas

  const createFabricCanvasIfNotExists = useCallback(
    async (links: Array<SvgWeAreLink>) => {
      //const debug = true
      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvasIfNotExists(): considering setting up fabric canvas...`
        )
      if (fabricCanvasRef.current) {
        if (debug)
          console.debug(
            `FabricJSCanvas.createFabricCanvasIfNotExists(): fabricCanvasRef.current already set, doing nothing.`
          )
        return
      }
      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvasIfNotExists(): fabricCanvasRef.current not set, checking canvasElRef.current...`
        )
      if (!canvasElRef.current) {
        console.error(
          `FabricJSCanvas.createFabricCanvasIfNotExists(): canvasElRef.current not set.`
        )
        return
      }
      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvasIfNotExists(): fabricCanvasRef.current not set and canvasEl.current is set, awaiting createFabricCanvas()...`
        )

      await createFabricCanvas(canvasElRef.current, links)

      if (debug)
        console.debug(
          `FabricJSCanvas.createFabricCanvasIfNotExists(): createFabricCanvas() done.`
        )
    },
    [createFabricCanvas]
  )

  useEffect(() => {
    //const debug = true
    if (debug)
      console.debug(
        `FabricJSCanvas.useEffect([]): calling createFabricCanvasIfNotExists()... canvasElRef.current: '${canvasElRef.current}', fabricCanvasRef.current: '${fabricCanvasRef.current}'`
      )
    // createFabricCanvasIfNotExists needs to be called from a useEffect because the DOM ref canvasElRef.current is not set
    // until the commit phase (JSX) which is after the FC top-level code has run
    // https://react.dev/learn/manipulating-the-dom-with-refs#when-react-attaches-the-refs
    createFabricCanvasIfNotExists(links) //canvasElRef.current)
    if (debug)
      console.debug(
        `FabricJSCanvas.useEffect([]): createFabricCanvasIfNotExists() returned, might still be running async.`
      )
  }, [createFabricCanvasIfNotExists, links]) //[createFabricCanvasIfNotExists])

  // observe links so that if one is deleted from the SidePanel we remove the drawing
  useEffect(() => {
    if (!fabricCanvas) {
      return
    }

    if (!links) {
      return
    }

    fabricCanvas.forEachObject((fabricObject: fabric.FabricObject) => {
      const linkId = fabricObject.get('weare_id')
      if (debug)
        console.debug(
          `FabricJSCanvas.useEffect([links]): checking we have link for shape with weare_id: ${linkId}`
        )
      if (linkId) {
        const link = links.find(link => link.id === linkId)
        if (!link) {
          if (debug)
            console.debug(
              `FabricJSCanvas.useEffect([links]): could not find link for shape with weare_id: ${linkId}, removing object from Fabric canvas.`
            )
          fabricCanvas.remove(fabricObject)
        }
      }
    })
  }, [fabricCanvas, links])

  if (debug)
    console.debug(
      `FabricJSCanvas: rendering fn done, returning JSX... isEditMode: ${isEditMode}, canvasElRef.current: '${canvasElRef.current}', fabricCanvasRef.current: '${fabricCanvasRef.current}'`
    )

  return (
    <>
      <canvas
        // Fabric is initialised on this canvas, it sets some attributes
        id="annotatedImageFabricCanvasId"
        ref={canvasElRef}
        style={{}}
        //onClick={onCanvasClick}
      />
      {isEditMode && fabricCanvas && (
        <EditMode
          canvas={fabricCanvas} //needs to be localState not a ref or this isn't re-rendered when the ref is set
          links={links}
          calledByCanvasWhenShapeSelected={calledByCanvasWhenShapeSelected}
          calledByEditModeOnExitingEditMode={() => setIsEditMode(false)}
          updateLocalStateAndSaveLinks={updateLocalStateAndSaveLinks}
          onFabricObjectsCreated={onFabricObjectsCreated}
          selectedObjectWeAreId={selectedObjectWeAreId}
        />
      )}
    </>
  )
}

interface EditModeProps {
  canvas: fabric.Canvas
  links: Array<WeAreLink>
  calledByCanvasWhenShapeSelected: CallableFunction
  calledByEditModeOnExitingEditMode: CallableFunction
  updateLocalStateAndSaveLinks?: CallableFunction
  onFabricObjectsCreated?: (fabricObjects: fabric.Object[]) => void
  selectedObjectWeAreId?: string
}

const EditMode = ({
  canvas,
  links,
  calledByCanvasWhenShapeSelected,
  calledByEditModeOnExitingEditMode,
  updateLocalStateAndSaveLinks,
  onFabricObjectsCreated,
  selectedObjectWeAreId,
}: EditModeProps) => {
  const debug = false
  const [activeTool, setActiveTool] = useState<string | null>(null)

  if (debug)
    console.debug(
      `EditMode(): rendering... activeTool: ${activeTool}, selectedObjectWeAreId: '${selectedObjectWeAreId}', canvas: ${canvas}` //, file: ${file}`
    )

  const creationTool = useMemo(() => {
    return {
      isCreating: false,
      type: null as string | null,
      creatingObject: null as fabric.FabricObject | null,
      isDragging: false,
      startPosX: null as number | null,
      startPosY: null as number | null,
      lastPosX: null as number | null,
      lastPosY: null as number | null,
      // used when creating a polygon
      polyLines: [] as Array<fabric.Line>,
      polyPoints: [] as Array<fabric.Point>,
      //lineCounter: 0 as number,
    }
  }, [])

  const findLinkWithIdParam = (links: Array<SvgWeAreLink>, linkId: string) => {
    return links.find(link => link.id === linkId)
  }

  // Queries the canvas for shapes, serializes them to SVG and stores the SVG in their link object
  // called by EditMode on dismount. onSaveLinks() is called after this.
  const updateSvgInLinks = useCallback(
    (canvas: fabric.Canvas, links: Array<SvgWeAreLink>) => {
      if (debug)
        console.debug(
          `EditMode.updateSvgInLinks(): called with existing links:`,
          links
        )

      const changedLinks: WeAreLink[] = []
      canvas.getObjects().forEach((fabricObject: fabric.FabricObject) => {
        const weareLinkId = fabricObject.get('weare_id')
        if (!weareLinkId) {
          console.error(
            `FabricJSCanvas.fabricObject.toSVG(): fabricObject.get('weare_id') not set, not patching generated SVG.`
          )
          return undefined
        }

        const existingLink = findLinkWithIdParam(links, weareLinkId)
        if (!existingLink) {
          console.error(
            `FabricJSCanvas.updateSvgInLinks(): findLinkWithId(weareLinkId: '${weareLinkId}') returned null, `
          )
        } else {
          const svg = fabricObject.toSVG()
          if (existingLink.annotationsSvg !== svg) {
            // can't directly modify existingLink property as it's local state
            const linkCopy = structuredClone(existingLink)
            linkCopy.annotationsSvg = svg // clear value if shape deleted
            changedLinks.push(linkCopy)
          }
        }
      })
      if (debug)
        console.debug(
          `EditMode.updateSvgInLinks(): updated links, returning changedLinks:`,
          changedLinks
        )
      return changedLinks
    },
    [debug]
  )

  const startRectCreation = useCallback(
    (top: number, left: number) => {
      //const debug = true
      if (debug)
        console.debug(
          `EditMode.startRectCreation(): creating rect and setting properties:`,
          shapePropsDrawingNew
        )
      const rect = new fabric.Rect({
        top,
        left,
        ...shapePropsDrawingNew,
      })
      rect.hoverCursor = 'nwse-resize'

      if (debug)
        console.debug(
          `EditMode.startRectCreation(): set canvas cursor to 'nwse-resize', returning Rect:`,
          rect
        )

      return rect
    },
    [debug]
  )

  // called on mouse move if we have started creating a rectangle
  const sizeNewRect = useCallback(
    (fabricEvent: fabric.TPointerEventInfo) => {
      //const debug = true

      if (
        creationTool.creatingObject &&
        creationTool.startPosX !== null &&
        creationTool.startPosY !== null
      ) {
        const [adjustedX, adjustedY] = [
          fabricEvent.scenePoint.x,
          fabricEvent.scenePoint.y,
        ]

        let width, height

        if (adjustedX > creationTool.startPosX) {
          width = adjustedX - creationTool.startPosX
          creationTool.creatingObject.set('left', creationTool.startPosX)
        } else {
          width = creationTool.startPosX - adjustedX
          creationTool.creatingObject.set('left', adjustedX)
        }
        if (adjustedY > creationTool.startPosY) {
          height = adjustedY - creationTool.startPosY
          creationTool.creatingObject.set('top', creationTool.startPosY)
        } else {
          height = creationTool.startPosY - adjustedY
          creationTool.creatingObject.set('top', adjustedY)
        }

        // set the pointer style depending on where the pointer is relative to the start position
        if (
          (adjustedX > creationTool.startPosX &&
            adjustedY > creationTool.startPosY) ||
          (adjustedX < creationTool.startPosX &&
            adjustedY < creationTool.startPosY)
        ) {
          // below right of the start point or above left of the start point
          canvas.elements.upper.el.style.cursor = 'nwse-resize'
        } else {
          // above right of the start point or below left of the start point
          canvas.elements.upper.el.style.cursor = 'nesw-resize'
        }

        if (debug)
          console.debug(
            `EditMode.sizeNewRect(): fabricEvent.pointer.x: ${fabricEvent.pointer.x}, fabricEvent.pointer.y: ${fabricEvent.pointer.y}; adjustedX: ${adjustedX}, adjustedY: ${adjustedY}; creationTool.creatingObject.left: ${creationTool.creatingObject.left}, creationTool.creatingObject.top: ${creationTool.creatingObject.top}; width: ${width}   Height: ${height}`
          )

        // if (creationTool.creatingObject !== fabricEvent.target) {
        //   console.error(
        //     `EditMode.sizeNewRect(): creationTool.creatingObject !== fabricEvent.target. target:`,
        //     fabricEvent.target
        //   )
        // }
        creationTool.creatingObject.set('width', width)
        creationTool.creatingObject.set('height', height)
        creationTool.creatingObject.setCoords()
        canvas.requestRenderAll()
      }
    },
    [
      canvas,
      creationTool.creatingObject,
      creationTool.startPosX,
      creationTool.startPosY,
      debug,
    ]
  )

  const CLEAR_DRAWING_MODE_ON_COMPLETE = true

  const endCreation = useCallback(
    (fabricObject: fabric.FabricObject) => {
      // if (debug) console.debug(
      //   `FabricJSCanvas.setupToolboxCanvas.endCreation(): creatingObject:`,
      //   creationTool.creatingObject
      // )
      if (debug)
        console.debug(
          `FabricJSCanvas.setupToolboxCanvas.endCreation(): fabricObject:`,
          fabricObject
        )

      //give the new shape our default styles
      fabricObject.set(shapePropsEditing)

      const newId = `NEW-${uuidv4()}`

      //give the new shape a unique ID
      fabricObject.set('weare_id', newId)

      if (debug)
        console.debug(
          `FabricJSCanvas.setupToolboxCanvas.endCreation(): after applying shapeStyle, fabricObject: `,
          fabricObject
        )

      creationTool.creatingObject = null
      creationTool.isCreating = false
      if (CLEAR_DRAWING_MODE_ON_COMPLETE) {
        creationTool.type = null
        setActiveTool(null)
      }

      if (onFabricObjectsCreated) {
        onFabricObjectsCreated([fabricObject])
      }

      if (calledByCanvasWhenShapeSelected) {
        calledByCanvasWhenShapeSelected(fabricObject)
      }
    },
    [
      CLEAR_DRAWING_MODE_ON_COMPLETE,
      calledByCanvasWhenShapeSelected,
      creationTool,
      debug,
      onFabricObjectsCreated,
    ]
  )

  const addPolyPoint = useCallback(
    (x: number, y: number) => {
      if (debug)
        console.debug(
          `addPolyPoint(): called with ${x}, ${y}; creationTool:`,
          creationTool
        )

      canvas.selection = false
      creationTool.startPosX = x
      creationTool.startPosY = y
      creationTool.polyPoints.push(new fabric.Point(x, y))
      const line = new fabric.Line([x, y, x, y], {
        // strokeWidth: 3,
        // selectable: false,
        // stroke: 'red',

        ...shapePropsDrawingNew,
      })
      creationTool.polyLines.push(line)

      line.hoverCursor = 'crosshair'
      canvas.add(line)
      if (debug) console.debug(`addPolyPoint(): added line to canvas`, line)
      canvas.on('mouse:up', function (options) {
        canvas.selection = true
      })
    },
    [canvas, creationTool, debug]
  )

  const startPolyCreation = useCallback(
    (x: number, y: number) => {
      creationTool.polyPoints = []
      creationTool.polyLines = []
      addPolyPoint(x, y)

      // add a listener to draw the final line on double-click
      canvas.once('mouse:dblclick', function (fabricEvent) {
        // clean up the temporary lines created when drawing the polygon
        creationTool.polyLines.forEach(function (value, index, ar) {
          canvas.remove(value)
        })
        // make a copy of the first point at the end to close the polygon
        creationTool.polyPoints.push(
          new fabric.Point(
            creationTool.polyPoints[0].x,
            creationTool.polyPoints[0].y
          )
        )
        const polygon = new fabric.Polyline(creationTool.polyPoints, {
          ...shapePropsEditing,
        })

        polygon.controls = fabric.controlsUtils.createPolyControls(
          polygon.points.length,
          { cursorStyle: 'crosshair' } //pass custom options
        )

        polygon.hoverCursor = 'crosshair'

        canvas.add(polygon)
        canvas.requestRenderAll()

        creationTool.polyPoints = []
        creationTool.polyLines = []

        endCreation(polygon)
      })
    },
    [addPolyPoint, canvas, creationTool, endCreation]
  )

  const startCreation = useCallback(
    (fabricEvent: fabric.TPointerEventInfo) => {
      //const debug = true
      if (debug)
        console.debug(`startCreation(): called, fabricEvent:`, fabricEvent)
      if (debug)
        console.debug(`startCreation(): called, creationTool:`, creationTool)
      creationTool.isCreating = true

      const [adjustedX, adjustedY] = [
        fabricEvent.scenePoint.x,
        fabricEvent.scenePoint.y,
      ]

      creationTool.startPosX = adjustedX
      creationTool.startPosY = adjustedY
      if (creationTool.type === 'rect') {
        if (debug)
          console.debug(
            `startCreation(): drawing rect from: ${adjustedX}:${adjustedY} - calling startRectCreation()...`,
            fabricEvent
          )
        creationTool.creatingObject = startRectCreation(adjustedY, adjustedX)
        return creationTool.creatingObject
      } else if (creationTool.type === 'poly') {
        startPolyCreation(adjustedX, adjustedY)
      } else if (creationTool.type === 'delete') {
        // do nothing
      } else {
        //endCreation()
        console.error(`startCreation(): Unknown type: ${creationTool.type}`)
      }

      return null
    },
    [creationTool, startPolyCreation, startRectCreation, debug]
  )

  //called as the mouse moves in-between points, just updates the newest
  //line's end point to follow the mouse
  const moveEndOfNewestPolyLine = useCallback(
    (fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      const x = fabricEvent.scenePoint.x
      const y = fabricEvent.scenePoint.y
      if (
        creationTool.polyLines &&
        creationTool.polyLines.length > 0 &&
        creationTool.polyLines[0] !== null &&
        creationTool.polyLines[0] !== undefined
      ) {
        creationTool.polyLines[creationTool.polyLines.length - 1].set({
          x2: x,
          y2: y,
        })

        canvas.requestRenderAll()
      }
    },
    [canvas, creationTool.polyLines]
  )

  const onMouseDownEditMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      //const debug = true
      if (debug) {
        console.debug(
          `EditMode.onMouseDownEditMode(): creationTool: `,
          creationTool
        )
        console.debug(`EditMode.onMouseDownEditMode(): canvas: `, canvas)
        console.debug(
          `EditMode.onMouseDownEditMode(): fabricEvent: `,
          fabricEvent
        )
      }

      if (creationTool.isCreating) {
        fabricEvent.e.preventDefault()
        fabricEvent.e.stopPropagation()
        if (creationTool.type === 'rect') {
          if (creationTool.creatingObject) {
            if (debug)
              console.debug(
                `EditMode.onMouseDownEditMode(): calling endCreation(): `,
                creationTool.creatingObject
              )

            endCreation(creationTool.creatingObject)
          } else {
            console.error(
              `EditMode.onMouseDownEditMode(): creationTool.creatingObject not set, doing nothing.`
            )
          }
        } else if (creationTool.type === 'poly') {
          addPolyPoint(fabricEvent.scenePoint.x, fabricEvent.scenePoint.y)
        }
      } else if (creationTool.type && creationTool.type !== 'delete') {
        // not isCreating but type is set to something other than delete
        fabricEvent.e.preventDefault()
        fabricEvent.e.stopPropagation()
        if (debug)
          console.debug(
            `EditMode.onMouseDownEditMode(): about to call startCreation, creationTool.type: '${creationTool.type}', fabricEvent:`,
            fabricEvent
          )
        const creatingObject = startCreation(fabricEvent)

        if (creatingObject) {
          if (debug)
            console.debug(
              `EditMode.onMouseDownEditMode(): startCreation() returned new shape, about to call canvas.add():`,
              creatingObject
            )
          canvas.add(creatingObject)
        }
      } else if (!canvas.getActiveObject()) {
        // no object selected and either (no tool selected or delete) - pan!
        if (debug)
          console.debug(
            `EditMode.onMouseDownEditMode(): called when isCreating false and type not set, initiating panning...`,
            fabricEvent
          )

        fabricEvent.e.preventDefault()
        fabricEvent.e.stopPropagation()

        initPan(canvas, fabricEvent)
      }
      if (debug) console.debug(`EditMode.onMouseDownEditMode(): done.`)
    },
    [addPolyPoint, canvas, creationTool, endCreation, startCreation, debug]
  )

  // called by selection:updated, selection:created, selection:cleared
  const onShapeSelectedEditMode = useCallback(
    (event: any) => {
      //const debug = true

      if (debug)
        console.debug(
          `EditMode.onShapeSelectedEditMode(): called with event, creationTool:`,
          event,
          creationTool
        )
      if (!event.e || !event.e.defaultPrevented) {
        canvas.forEachObject((fabricObject: fabric.FabricObject) => {
          const objectIsSelected = canvas
            .getActiveObjects()
            .includes(fabricObject)
          fabricObject.hoverCursor = objectIsSelected ? 'grab' : null

          if (debug)
            console.debug(
              `EditMode.onShapeSelectedEditMode(): objectIsSelected: ${objectIsSelected} - set shape's hoverCursor to: ${fabricObject.hoverCursor}`
            )
        })

        if (event.selected) {
          if (event.selected.length === 1) {
            const fabricObject: fabric.FabricObject = event.selected[0]
            if (creationTool?.type === 'delete') {
              if (debug)
                console.debug(
                  `EditMode.onShapeSelectedEditMode(): called when creationTool.type is delete - fabricObject:`,
                  fabricObject
                )
              const shape_weare_id = fabricObject.get('weare_id')
              if (shape_weare_id && updateLocalStateAndSaveLinks) {
                const linksCopy = links.filter(
                  link => link.id !== shape_weare_id
                )
                if (debug)
                  console.debug(
                    `EditMode.onShapeSelectedEditMode(): called when creationTool.type is delete - removed link with id '${shape_weare_id}', calling updateLocalStateAndSaveLinks()... linksCopy:`,
                    linksCopy
                  )

                updateLocalStateAndSaveLinks(
                  linksCopy,
                  undefined,
                  LinksChangedReason.SHAPE_DELETED
                )
              } else {
                // a random unlinked shape, just remove it
                canvas.remove(fabricObject)
              }

              if (CLEAR_DRAWING_MODE_ON_COMPLETE) {
                creationTool.type = null
                setActiveTool(null)

                if (debug)
                  console.debug(
                    `EditMode.onShapeSelectedEditMode(): shape deleted, clearing delete toolbox mode, setting hover cursor on all shapes to null`
                  )
                setHoverCursorOnAllShapes(null, canvas)
              }
              return
            } else if (creationTool.type) {
              // creating something else, don't select the object
              canvas.discardActiveObject()
            } else {
              if (calledByCanvasWhenShapeSelected) {
                calledByCanvasWhenShapeSelected(fabricObject)
              }
            }
          } else if (event.selected.length === 0) {
            if (calledByCanvasWhenShapeSelected) {
              calledByCanvasWhenShapeSelected(null)
            }
          }
        } else if (event.deselected) {
          if (canvas.getActiveObjects().length === 0) {
            if (selectedObjectWeAreId) {
              if (calledByCanvasWhenShapeSelected) {
                if (debug)
                  console.debug(
                    `EditMode.onShapeSelectedEditMode(): called with 'event.deselected=true' when selectedObjectWeAreId is set (${selectedObjectWeAreId}), calling calledByCanvasWhenShapeSelected(null)...`
                  )
                calledByCanvasWhenShapeSelected(null)
              }
            }
          }
        }
      }
    },
    [
      CLEAR_DRAWING_MODE_ON_COMPLETE,
      calledByCanvasWhenShapeSelected,
      canvas,
      creationTool,
      debug,
      links,
      selectedObjectWeAreId,
      updateLocalStateAndSaveLinks,
    ]
  )

  const onMouseMoveEditMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo) => {
      const debug = false

      if (debug)
        console.debug(
          `EditMode.onMouseMoveEditMode(): creationTool:`,
          creationTool
        )

      if (creationTool.isCreating) {
        if (creationTool.type === 'rect') {
          if (debug)
            console.debug(
              `EditMode.onMouseMoveEditMode(): calling sizeNewRect()...`
            )
          sizeNewRect(fabricEvent)
        } else if (creationTool.type === 'poly') {
          //update the current line to have the new end coordinates
          moveEndOfNewestPolyLine(fabricEvent)
        }
      } else {
        if (creationTool.type && creationTool.type !== 'delete') {
          if (debug)
            console.debug(
              `EditMode.onMouseMoveEditMode(): creationTool.isCreating is not set and creationTool.type is set: '${creationTool.type}'...`
            )
          // not panning, not initiated creating yet, but has selected a tool
          if (canvas) {
            // if (debug)
            //   console.debug(
            //     `EditMode.onMouseMoveEditMode(): setting canvas' mouse cursor to 'crosshair'...`
            //   )
            // canvas.elements.upper.el.style.cursor = 'crosshair'
          } else {
            console.error(
              `EditMode.onMouseMoveEditMode(): creationTool.isCreating is not set and creationTool.type is set: '${creationTool.type}' but canvas is not.`
            )
          }
        } else {
          pan(canvas, fabricEvent)
        }
      }
    },
    [canvas, creationTool, moveEndOfNewestPolyLine, sizeNewRect]
  )

  const onMouseUpEditMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo) => {
      cancelPan()

      if (creationTool.isDragging) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        canvas.setViewportTransform(canvas.viewportTransform)
        creationTool.isDragging = false
        //canvas.selection = true
        if (debug)
          console.debug(
            `on mouse:up: canvas.viewportTransform[4]: ${canvas.viewportTransform[4]}, canvas.viewportTransform[5]: ${canvas.viewportTransform[5]}`,
            canvas.viewportTransform
          )
      } else {
        if (!creationTool.isCreating) {
          // this is costly and could make things a bit laggy, but is needed very occasionally
          // when a polygon's control is dragged
          // doesn't work :-/
          // const o = canvas.getActiveObject()
          // if (o) {
          //   Object.entries(o.controls).forEach(([k, v], i) => {
          //     if (debug) console.debug(
          //       `on mouse:up: getActiveObject().controls[${i}]: key: ${k}, value:`,
          //       v
          //     )
          //   })
          //   if (debug) console.debug(`on mouse:up: calling getActiveObject().setCoords()...`)
          //   //o.setCoords()
          // }
          // if (debug) console.debug(`on mouse:up: calling canvas.requestRenderAll()...`)
          // canvas.requestRenderAll()
        }
      }
    },
    [canvas, creationTool, debug]
  )

  const deregisterEditModeCanvasMouseEventListeners = useRef<
    CallableFunction | undefined
  >()

  // cancel and re-register the canvas mouse event listeners when
  // the canvas or useCallback functions change, and on dismount
  useEffect(() => {
    if (debug)
      console.debug(
        `EditMode.useEffect([]): EditMode mounting, registering canvas event listeners...`
      )
    if (deregisterEditModeCanvasMouseEventListeners.current) {
      deregisterEditModeCanvasMouseEventListeners.current()
    }
    deregisterEditModeCanvasMouseEventListeners.current = canvas.on({
      'selection:updated': onShapeSelectedEditMode,
      'selection:created': onShapeSelectedEditMode,
      'selection:cleared': onShapeSelectedEditMode,
      'mouse:down': onMouseDownEditMode,
      'mouse:up': onMouseUpEditMode,
      'mouse:move': onMouseMoveEditMode,
    })
    return () => {
      if (debug)
        console.debug(
          `EditMode.useEffect([]): EditMode dismounting, de-registering canvas event listeners...`
        )
      if (deregisterEditModeCanvasMouseEventListeners.current) {
        deregisterEditModeCanvasMouseEventListeners.current()
        deregisterEditModeCanvasMouseEventListeners.current = undefined
      }
    }
  }, [
    canvas,
    debug,
    onMouseDownEditMode,
    onMouseMoveEditMode,
    onMouseUpEditMode,
    onShapeSelectedEditMode,
  ])

  // on EditMode dismount, serialize all shapes to SVG
  // and call calledByCanvasWithSerialisedDrawingsOnDismount()...
  useEffect(
    () => {
      if (debug)
        console.debug(
          `EditMode.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, canvas, links]): mounting, noop`
        )

      return () => {
        if (debug)
          console.debug(
            `EditMode.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, canvas, links]): dismounting; calling updateSvgInLinks() then updateLocalStateAndSaveLinks() if provided...`
          )

        if (SAVE_LINKS_ON_EDIT_DISMOUNT) {
          // deselect any selected shape
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          canvas.discardActiveObject({ defaultPrevented: true })

          if (updateLocalStateAndSaveLinks) {
            if (canvas) {
              if (debug)
                console.debug(
                  `EditMode.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, canvas, links]): calling serialiseDrawingsToSvg()...`
                )

              // we want to do a save to the API if anything about the links have changed
              // could be either the SVG drawing OR the link target, or one could be deleted.
              // updateSvgInLinks() tells us which SVGs changed but not about targets.
              // Perhaps we could save link targets as they are edited, then this could only save
              // when changedAnnotatedLinks is not empty
              const changedAnnotatedLinks = updateSvgInLinks(canvas, links) // defined in EditMode
              updateLocalStateAndSaveLinks(links, changedAnnotatedLinks) //, EDIT_DISMOUNT) //MediaDetail.onSaveLinks()
            }
          }
        }

        //TODO EditMode should dispose of the canvas IF the image is being closed and not just leaving edit mode
        //canvas.dispose()
      }
    },
    // we don't want any dependencies in here because we want this to run only when the EditMode component is dismounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      //  canvas, links, updateLocalStateAndSaveLinks, updateSvgInLinks
    ]
  )

  // react to selectedObjectWeAreId prop changing by setting the canvas' active object
  useEffect(() => {
    if (debug)
      console.debug(
        `FabricJSCanvas.useEffect([selectedObjectWeAreId]): selectedObjectWeAreId: '${selectedObjectWeAreId}'`
      )

    if (!canvas) {
      return
    }

    const existingActiveObject = canvas.getActiveObject()

    if (selectedObjectWeAreId) {
      if (existingActiveObject) {
        if (existingActiveObject.get('weare_id') === selectedObjectWeAreId) {
          if (debug)
            console.debug(
              `FabricJSCanvas.useEffect([selectedObjectWeAreId]): selectedObjectWeAreId '${selectedObjectWeAreId}' already active, doing nothing.`
            )
          return
        }
      }
      if (debug)
        console.debug(
          `FabricJSCanvas.useEffect([selectedObjectWeAreId]): looking in canvas for object with weare_id: '${selectedObjectWeAreId}'...`
        )
      const canvasObject = canvas
        .getObjects()
        .find(o => o.get('weare_id') === selectedObjectWeAreId)
      if (canvasObject) {
        if (debug)
          console.debug(
            `FabricJSCanvas.useEffect([selectedObjectWeAreId]): setting canvas' active object to object with weare_id: '${selectedObjectWeAreId}': `,
            canvasObject
          )

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.setActiveObject(canvasObject, { defaultPrevented: true })
        canvas.requestRenderAll()
      }
    } else {
      if (existingActiveObject) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.discardActiveObject({ defaultPrevented: true })
      }
    }
  }, [canvas, debug, selectedObjectWeAreId])

  const setHoverCursorOnAllShapes = (
    newCursor: string | null,
    canvas: fabric.Canvas
  ) => {
    canvas.forEachObject((fabricObject: fabric.FabricObject) => {
      fabricObject.hoverCursor = newCursor
    })
  }
  const setSelectableOnAllShapes = (
    selectable: boolean,
    canvas: fabric.Canvas
  ) => {
    canvas.forEachObject((fabricObject: fabric.FabricObject) => {
      fabricObject.set('selectable', selectable)
    })
  }

  // called by useEffect observing activeTool, which is changed by the toolbox
  const onActiveToolChanged = useCallback(
    (activeTool: string | null, canvas: fabric.Canvas, creationTool: any) => {
      const debug = true

      if (debug)
        console.debug(
          `EditMode.onActiveToolChanged(): activeTool changed to: ${activeTool}`
        )
      creationTool.type = activeTool

      if (activeTool) {
        // deselect current shape and set crosshair pointer when hovering over any shape
        // for either create new shape or delete.
        // When creating new shape, canvas background also has crosshair.
        if (debug)
          console.debug(
            `EditMode.onActiveToolChanged(): activeTool set, calling discardActiveObject() and setting cursor on all shapes to 'crosshair'...`
          )

        canvas.discardActiveObject()
        setHoverCursorOnAllShapes('crosshair', canvas)
        if (activeTool === 'delete') {
          setSelectableOnAllShapes(true, canvas)
        } else {
          if (debug)
            console.debug(
              `EditMode.onActiveToolChanged(): activeTool set to something that's not 'delete', disabling 'selectable' on all shapes, setting canvas.defaultCursor to 'crosshair'...`
            )

          setSelectableOnAllShapes(false, canvas)
          canvas.defaultCursor = 'crosshair'
        }
      } else {
        if (debug)
          console.debug(
            `EditMode.onActiveToolChanged(): activeTool not set, setting canvas default cursor to 'grab' and hover cursor on all shapes to null...`
          )

        canvas.defaultCursor = 'grab'

        setSelectableOnAllShapes(true, canvas)
        setHoverCursorOnAllShapes(null, canvas)
      }

      canvas.requestRenderAll() // especially needed for discardActiveObject()
    },
    []
  )

  // observe activeTool changed by FabricToolbox
  useEffect(() => {
    onActiveToolChanged(activeTool, canvas, creationTool)
  }, [activeTool, canvas, creationTool, onActiveToolChanged])

  const onExitingEditMode = (canvas: fabric.Canvas, save: boolean) => {
    //const debug = true

    if (selectedObjectWeAreId) {
      //deselect any selected shape

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      canvas.discardActiveObject({ defaultPrevented: true })

      calledByCanvasWhenShapeSelected(null)
    }

    // we want to do a save to the API if anything about the links have changed
    // could be either the SVG drawing OR the link target, or one could be deleted
    // updateSvgInLinks() tells us which SVGs changed but not about targets.
    // Perhaps we could save link targets as they are edited, then this could only save
    // when changedAnnotatedLinks is not empty

    if (save) {
      if (debug)
        console.debug(
          `EditMode.onExitingEditMode(): save is: ${save}; calling updateSvgInLinks(), links:`,
          links
        )
      const changedAnnotatedLinks = updateSvgInLinks(canvas, links) // defined in EditMode

      if (debug)
        console.debug(
          `EditMode.onExitingEditMode(): updateSvgInLinks() returned changedAnnotatedLinks:`,
          changedAnnotatedLinks
        )

      //updateLocalStateAndSaveLinks is passed in from AnnotatedImage
      if (updateLocalStateAndSaveLinks) {
        if (debug)
          console.debug(
            `EditMode.onExitingEditMode(): calling updateLocalStateAndSaveLinks() with changedAnnotatedLinks:`,
            changedAnnotatedLinks
          )

        updateLocalStateAndSaveLinks(
          links,
          changedAnnotatedLinks,
          LinksChangedReason.TOOLBOX_CLOSED
        ) // calls MediaDetail.onSaveLinks()
      }
    }

    calledByEditModeOnExitingEditMode() // defined in FabricJSCanvas, just sets isEditing to false
  }

  return (
    <>
      <FabricToolbox activeTool={activeTool} onToolChanged={setActiveTool} />

      <Box
        id="closeButtons"
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          marginRight: '10px',
          marginBottom: '10px',
          backgroundColor: 'white',
          padding: 1,
          borderRadius: '4px',
          display: 'flex',
          gap: 1,
          flexDirection: 'row-reverse',
          zIndex: 2, // on top of the crop/edit buttons
        }}
      >
        <>
          <>
            {/*  @ts-expect-error some props are not mandatory }
          { <ConfirmDialog
            onConfirm={async () => onExitingEditMode(canvas, false)}
            submitText="Exit"
            cancelText="Continue editing"
            submitColor="error"
            trigger={props => (
              <Button
                color="error"
                permissionAction={ACTION_ALL_ACCESS}
                {...props}
              >
                Exit
              </Button>
            )}
          >
            All changes will be lost, are you sure you want to exit?
          </ConfirmDialog> */}

            {/* @ts-expect-error some props are not mandatory
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={togglePreview}
              variant="outlined"
            >
              {!preview ? 'Preview' : 'Continue editing'}
            </Button> */}

            {/*  @ts-expect-error some props are not mandatory */}
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={async () => onExitingEditMode(canvas, true)}
            >
              Save
            </Button>
          </>
        </>
        {/* ) : (
          <>
            {(mapIsFullWindow || mapIsFullScreen) && !preview && (
              <Button permissionAction={ACTION_ALL_ACCESS} onClick={closeMap}>
                Close
              </Button>
            )}
            {showEditButton && !publicContext && (
              <Button
                variant="outlined"
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                Edit
              </Button>
            )}
          </>
        )} */}
      </Box>
    </>
  )
}

export type OnAnnotationSvgUpdatedCallback = (newSvgString?: string) => void

interface AnnotatedImageProps {
  file: string
  type: string
  annotationSvg: string | null
  initialLinks: Array<SvgWeAreLink>
  originalImageWidthPx: number
  originalImageHeightPx: number
  editingImageAnnotations: boolean
  setEditingImageAnnotations: CallableFunction
  onSaveLinks: CallableFunction
  treeSlug: string
}
const AnnotatedImage = ({
  file,
  type,
  initialLinks,
  originalImageWidthPx,
  originalImageHeightPx,
  editingImageAnnotations, // the caller will re-render this component with this prop 'true' when the user clicks the edit button
  setEditingImageAnnotations,
  onSaveLinks,
  treeSlug,
}: AnnotatedImageProps) => {
  const [currentSidebarShapeIdx, setCurrentSidebarShapeIdx] = useState(-1)
  const [links, setLinks] = useState<SvgWeAreLink[]>(initialLinks ?? [])
  const [sidePanelCorner, setSidePanelCorner] = useState<string>()

  const history = useHistory() // navigate to items when clicked
  const publicContext: any | null = useContext(PublicContext) // cast because PublicContext is constructed with an empty string not an object
  const publicTreeSlug = publicContext?.treeSlug

  const treeSlugFromRedux = useSelector(selectAuthorisedTreeSlug)
  if (!treeSlug) {
    treeSlug = treeSlugFromRedux
  }

  if (debug)
    console.debug(
      `AnnotatedImage: rendering, treeSlug: ${treeSlug}, editingImageAnnotations: ${editingImageAnnotations}, links:`, //, isEditingDrawing: ${isEditingDrawing}`
      links
    )

  const findIndexOfLinkWithIdParam = (
    id: string,
    linksParam: WeAreLink[]
  ): number => {
    const idx = linksParam.findIndex(si => si.id === id)

    if (debug)
      console.debug(
        `AnnotatedImage.findFeatureWithIdParam(): done - called with id: '${id}', found link at idx ${idx}.`
      )
    return idx
  }

  //this version takes the links as a parameter so it doesn't need
  //to be in the react context to get at local state
  const findLinkWithIdParam = <T extends WeAreLink>(
    id: string,
    linksParam: T[]
  ): T | null => {
    let f = null

    f = linksParam.find(si => si.id === id)

    if (debug)
      console.debug(
        `AnnotatedImage.findFeatureWithIdParam(): done - called with id: '${id}', found feature:`,
        f
      )
    return f ?? null
  }

  const findLinkWithIdViaSetAsync = useCallback(
    async (id: string): Promise<WeAreLink | undefined> => {
      let link
      await setLinks(existingLinks => {
        link = existingLinks.find(si => si.id === id)
        if (!link) {
          console.warn(
            `AnnotatedImage.findLinkWithIdViaSetAsync(): link with id '${id}' not found in existingLinks`,
            existingLinks
          )
        } else {
          if (debug)
            console.debug(
              `AnnotatedImage.findLinkWithIdViaSet(): link with id '${id}' found in existingLinks:`,
              link
            )
        }
        return existingLinks
      })

      return link
    },
    []
  )

  const setCurrentSidebarShapeIdxFromShapeId = useCallback((id: string) => {
    setLinks((existingLinks: Array<WeAreLink>) => {
      const idx = findIndexOfLinkWithIdParam(id, existingLinks)
      setCurrentSidebarShapeIdx(idx)
      return existingLinks
    })
  }, [])

  // called when the mouse pointer hovers over a shape in the canvas.
  // find the shape in the features list and call
  // setCurrentSidebarShapeIdx() to set that shape as the one seen in the side info panel,
  // This is called by FabricJSCanvas so outside the react context - will not have
  // up-to-date local state.
  const calledByCanvasWhenShapeSelected = useCallback(
    (shape: fabric.FabricObject | null) => {
      //const debug = true
      if (debug)
        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeSelected(): called with shape`,
          shape
        )
      if (!shape) {
        setCurrentSidebarShapeIdx(-1)
        return
      }
      const id = shape.get('weare_id')
      if (debug)
        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeSelected(): called with shape with weare_id: ${id}`
        )
      if (id) {
        if (debug)
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeSelected(): calling setCurrentSidebarShapeIdxFromShapeId(${id})...`
          )
        setCurrentSidebarShapeIdxFromShapeId(id)
        if (debug)
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeSelected(): setCurrentSidebarShapeIdxFromShapeId() returned.`
          )
      }

      shape.canvas?.requestRenderAll()
    },
    [setCurrentSidebarShapeIdxFromShapeId]
  )

  //TODO replace calledByCanvasWhenShapeClickedInViewMode with some sort of svg-href on the shape?
  const calledByCanvasWhenShapeClickedInViewMode = useCallback(
    async (shape: fabric.FabricObject | null) => {
      if (debug)
        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): called with publicTreeSlug '${publicTreeSlug}' and shape`,
          shape
        )
      if (!shape) {
        return
      }
      const id = shape.get('weare_id')
      if (debug)
        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): called with shape with weare_id: '${id}'`
        )
      if (id) {
        //const link = findLinkWithIdViaSet(id)
        const link = await findLinkWithIdViaSetAsync(id)
        if (debug)
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): found link with id '${id}'`,
            link
          )
        if (
          (treeSlug || publicTreeSlug) &&
          link &&
          link.instanceType &&
          link.instanceId //|| link.target?.id
        ) {
          const newItemUrl = publicTreeSlug
            ? generatePublicLinkForObject(
                publicTreeSlug,
                link.instanceType,
                link.instanceId
              )
            : generateLinkForObject(
                treeSlug,
                link.instanceType,
                link.instanceId
              )
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): generate(Public|)LinkForObject() returned  newItemUrl: '${id}'`
          )
          if (newItemUrl) {
            history.push(newItemUrl)
            // tell the caller we have handled the event
            return true
          }
        }
      }
    },
    [publicTreeSlug, findLinkWithIdViaSetAsync, history, treeSlug]
  )

  const createLinkFromFabricObject = (
    fabricObject: fabric.Object
  ): SvgWeAreLink => {
    const link: SvgWeAreLink = {
      id: fabricObject.get('weare_id') ?? '',
    }

    if (debug)
      console.debug(
        `AnnotatedImage.createFeatureFromFabricObject(): called with fabricObject:`,
        fabricObject
      )
    if (debug)
      console.debug(
        `AnnotatedImage.createFeatureFromFabricObject(): returning link:`,
        feature
      )
    return link
  }

  /**
   * Creates WeAreMapFeature objects for all shapes and adds them to 'features' local state
   *
   * Called from
   *  - populateCanvasFromSvg() inside createFabricCanvas()
   *  - endCreation
   *
   */
  const onFabricObjectsCreated = async (fabricObjects: fabric.Object[]) => {
    if (debug)
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): called with fabricObjects:`,
        fabricObjects
      )
    const createdLinks = fabricObjects.map(fo => createLinkFromFabricObject(fo))
    if (debug)
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): createdLinks:`,
        createdLinks
      )
    if (debug)
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): existing links:`,
        links
      )

    setLinks(existingLinks => {
      if (debug)
        console.debug(
          `AnnotatedImage.onFabricObjectsCreated().setLinks(): existingLinks:`,
          existingLinks
        )
      const newLinksToAdd = createdLinks.filter(
        f => !findLinkWithIdParam(f.id, existingLinks)
      )
      if (debug)
        console.debug(
          `AnnotatedImage.onFabricObjectsCreated().setLinks(): adding links:`,
          newLinksToAdd
        )
      if (!newLinksToAdd.length) {
        if (debug)
          console.debug(
            `AnnotatedImage.onFabricObjectsCreated().setLinks(): newFeaturesToAdd empty, leaving features as they are`
          )
        return existingLinks
      } else {
        const res = [...existingLinks, ...newLinksToAdd]
        if (debug)
          console.debug(
            `AnnotatedImage.onFabricObjectsCreated().setLinks(): setting features to:`,
            res
          )
        return res
      }
    })
    if (debug)
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): links now:`,
        links
      )
  } // end onFabricObjectsCreated

  // don't need this as now the map_blocks api returns the target's photo
  // whenever Links (local state) change, check if each one has its target object populated,
  // if not go get it from the API
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   if (debug)
  //     console.debug(
  //       `AnnotatedImage.useEffect([links]): considering fetching data for links:`,
  //       links
  //     )
  //   for (const l of links) {
  //     if (debug)
  //       console.debug(
  //         `AnnotatedImage.useEffect([links]): considering fetching data for link:`,
  //         l
  //       )
  //     if (l.instanceType && l.instanceId && !l.target) {
  //       if (l.instanceType === INSTANCE_TYPE_INDIVIDUAL) {
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         //@ts-ignore
  //         dispatch(fetchIndividual({ individualId: l.instanceId })).then(
  //           (response: any) => {
  //             if (debug)
  //               console.debug(
  //                 `AnnotatedImage.useEffect([links]): fetched individual:`,
  //                 response.payload
  //               )
  //             if (response.payload) {
  //               const givenName = response.payload.givenName || ''
  //               const surname = response.payload.surname || ''
  //               if (!l.target) {
  //                 l.target = {}
  //               }
  //               l.target.display =
  //                 givenName && surname
  //                   ? `${givenName} ${surname}`
  //                   : givenName || surname
  //
  //               l.target.photo = {
  //                 id: response.payload.photo?.id,
  //                 fileThumbnail: response.payload.photo?.fileThumbnail,
  //               }
  //               if (debug)
  //                 console.debug(
  //                   `AnnotatedImage.useEffect([links]): set link to:`,
  //                   l
  //                 )
  //               //force re-render because features have changed
  //               setLinks([...links])
  //             }
  //           }
  //         )
  //       } else {
  //         // its not an individual!
  //         //TODO lookup any item to get the title and photo
  //         //const item: any = null //fetchItem(f.target.id, f.instanceType)
  //         // if (debug) console.debug(`AnnotatedImage.useEffect(): fetched item:`, item)
  //         // if (item) {
  //         //   l.title = item.title
  //         //   if (item.fileMedium || item.fileThumbnail) {
  //         //     l.target.photo = {
  //         //       id: '',
  //         //       fileMedium: item.fileMedium,
  //         //       fileThumbnail: item.fileThumbnail,
  //         //     }
  //         //   }
  //         // }
  //         if (debug)
  //           console.debug(
  //             `AnnotatedImage.useEffect([links]): link is for unsupported item type '${l.instanceId}'.`
  //           )
  //       }
  //     }
  //   }
  // }, [dispatch, links])
  // END call the api to get target info

  // called by EditMode.onToolboxClosed()
  const updateLocalStateAndSaveLinks = async (
    allLinks: Array<SvgWeAreLink>,
    updatedLinks?: Array<SvgWeAreLink>,
    linksChangedReason?: LinksChangedReason
  ) => {
    //const debug = true

    if (debug)
      console.debug(
        `AnnotatedImage.updateLocalStateAndSaveLinks(): called, cloning allLinks and updating annotationsSvg in clone - allLinks:, updatedLinks:`,
        allLinks,
        updatedLinks
      )

    // update the immutable links by copying them to a new array
    // and if updatedLinks contains different svg for a link
    // clone the link (to make it mutable) and update the svg
    const newLinks = updatedLinks
      ? allLinks.map(existingLink => {
          const id = existingLink.id
          const updatedLink = findLinkWithIdParam(id, updatedLinks)
          if (updatedLink) {
            if (existingLink.annotationsSvg !== updatedLink.annotationsSvg) {
              return {
                ...existingLink,
                annotationsSvg: updatedLink.annotationsSvg,
              }
            }
          } else {
            console.error(
              `AnnotatedImage.updateLocalStateAndSaveLinks(): updatedLinks contained link not found in allLinks:`,
              updatedLink
            )
          }

          return existingLink
        })
      : allLinks

    if (debug)
      console.debug(
        `AnnotatedImage.updateLocalStateAndSaveLinks(): calling local state setLinks() with newLinks:`,
        newLinks
      )

    setLinks(newLinks)
    if (debug)
      console.debug(
        `AnnotatedImage.updateLocalStateAndSaveLinks(): setLinks() done.`
      )

    if (linksChangedReason === LinksChangedReason.TOOLBOX_CLOSED) {
      if (onSaveLinks) {
        console.debug(
          `AnnotatedImage.updateLocalStateAndSaveLinks(): calling onSaveLinks() with newLinks:`,
          newLinks
        )
        await onSaveLinks(newLinks) // defined in MediaDetail
      }
    }
  }

  const calcSidePanelCorner = (fabricEvent: fabric.TPointerEventInfo) => {
    if (debug)
      console.debug(
        `AnnotatedImage.calcSidePanelCorner(): fabricEvent:`,
        fabricEvent
      )
    const mousex = fabricEvent.viewportPoint.x
    const mousey = fabricEvent.viewportPoint.y

    const canvasWidth = fabricEvent.target?.canvas?.width
    const canvasHeight = fabricEvent.target?.canvas?.height
    if (canvasWidth && canvasHeight) {
      const canvasXcenter = canvasWidth / 2
      const canvasYcenter = canvasHeight / 2

      if (mousex < canvasXcenter) {
        if (mousey < canvasYcenter) {
          return 'bottomRight'
        } else {
          return 'topRight'
        }
      } else {
        if (mousey < canvasYcenter) {
          return 'bottomLeft'
        } else {
          return 'topLeft'
        }
      }
    }
  }

  const onMouseMove = useCallback((fabricEvent: fabric.TPointerEventInfo) => {
    if (debug)
      console.debug(`AnnotatedImage.onMouseMove(): fabricEvent:`, fabricEvent)

    //only really need to do this when a shape is selected, but it should be done AS
    //a shape is selected otherwise it uses the previously calced corner and jumps as soon
    //as the mouse is moved. If adding deps watch out for unnecessary re-renders.
    const newSidePanelCorner = calcSidePanelCorner(fabricEvent)
    setSidePanelCorner(newSidePanelCorner)
  }, [])

  return (
    <>
      <Box
        id="fabricJSCanvasContainer"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'clip', // stop a scrollbar appearing despite the heights being exactly the same (?)
        }}
      >
        <FabricJSCanvas
          file={file}
          originalImageWidthPx={originalImageWidthPx}
          originalImageHeightPx={originalImageHeightPx}
          links={links}
          calledByCanvasWhenShapeSelected={calledByCanvasWhenShapeSelected} //update side panel - calls setCurrentSidebarShapeIdx
          calledByCanvasWhenShapeClickedInViewMode={
            calledByCanvasWhenShapeClickedInViewMode
          }
          updateLocalStateAndSaveLinks={updateLocalStateAndSaveLinks}
          onFabricObjectsCreated={onFabricObjectsCreated}
          selectedObjectWeAreId={links[currentSidebarShapeIdx]?.id}
          isEditMode={editingImageAnnotations}
          setIsEditMode={setEditingImageAnnotations}
          onMouseMove={onMouseMove}
        />

        {links && (
          <DrawingInfosSidePanel
            links={links}
            setLinks={setLinks} // called when item deleted
            currentIndex={currentSidebarShapeIdx}
            setCurrentIndex={setCurrentSidebarShapeIdx}
            editMode={editingImageAnnotations}
            treeSlug={treeSlug}
            corner={editingImageAnnotations ? 'topLeft' : sidePanelCorner}
          />
        )}
      </Box>
    </>
  )
}

interface DrawingSidePanelProps {
  links: Array<SvgWeAreLink>
  setLinks: CallableFunction
  currentIndex: number
  setCurrentIndex: CallableFunction
  treeSlug: string
  editMode?: boolean
  corner?: string
}

const DrawingInfosSidePanel = ({
  links,
  setLinks,
  currentIndex,
  setCurrentIndex,
  treeSlug,
  editMode = false,
  corner = 'topLeft',
}: DrawingSidePanelProps) => {
  const [currentLink, setCurrentLink] = useState<WeAreLink>(links[currentIndex])

  if (debug)
    console.debug(
      `DrawingInfosSidePanel: rendering with currentIndex: ${currentIndex}, local state currentLink already:`,
      currentLink
    )

  // when currentIndex or links changes, call setCurrentLink()
  useEffect(() => {
    if (debug)
      console.debug(
        `DrawingInfosSidePanel.useEffect([currentIndex, setCurrentLink, links]): called with currentIndex: ${currentIndex}, features:`,
        links
      )
    const cl = links[currentIndex]
    if (debug)
      console.debug(
        `DrawingInfosSidePanel.useEffect([currentIndex, setCurrentLink, links]): called with currentIndex: ${currentIndex}, calling setCurrentLink() with cl:`,
        cl
      )
    setCurrentLink(cl)
  }, [currentIndex, setCurrentLink, links])

  const navigateLinks = (direction: boolean) => {
    if (debug)
      console.debug(
        `DrawingInfosSidePanel.navigateMarkers(): called with direction: ${direction}`
      )
    const newIndex = direction ? currentIndex + 1 : currentIndex - 1

    if (newIndex < 0) {
      setCurrentIndex(links.length - 1)
    } else if (newIndex >= links.length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(newIndex)
    }
  }

  return (
    <>
      <Box
        sx={{
          position: 'absolute', // absolute allows this box to overlay the map
          ...(corner === 'topRight'
            ? {
                top: 0,
                right: 0,
                marginRight: '64px', // go to the left of the 'crop' and 'edit' buttons
                marginTop: '10px',
              }
            : corner === 'bottomRight'
            ? {
                bottom: 0,
                right: 0,
                marginRight: '64px', // go to the left of the 'crop' and 'edit' buttons
                marginBottom: '10px',
              }
            : corner === 'bottomLeft'
            ? {
                bottom: 0,
                left: 0,
                marginLeft: editMode ? '10px' : '64px', // go to the right of the 'close' and 'previous image' arrow buttons
                marginBottom: '10px',
              }
            : {
                //default to topLeft
                top: 0,
                left: 0,
                marginLeft: editMode ? '10px' : '64px', // go to the right of the 'close' and 'previous image' arrow button
                marginTop: '10px',
              }),
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '400px',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        {editMode ? (
          <MarkerEditor
            locations={links.filter(link => link.annotationsSvg)}
            setLocations={setLinks}
            currentMarker={currentLink}
            setCurrentMarkerIndex={setCurrentIndex}
            flyToEditMode={() => {
              //noop
            }}
            dropPinMode={false}
            setDropPinMode={undefined}
            offerLinkSelector={true}
          />
        ) : (
          <ReadOnlyLeftPanel
            weareMapFeatures={links}
            navigateMarkers={navigateLinks}
            navigateToMarkerIndex={setCurrentIndex}
            currentSelectedFeature={currentLink}
            treeSlug={treeSlug}
            allowThreeD={false}
            flyToMarkersIn2d={undefined}
            setFlyToMarkersIn2d={undefined}
            smallMode={undefined}
            allowList={false}
            allowPrevNext={false}
          />
        )}
      </Box>
    </>
  )
}

export default AnnotatedImage
