import { useParams } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  fetchLinkedPageItem,
  selectLinkedPageItem,
  updateLinkedPageItem,
} from 'src/modules/page/pageSlice'
import { useActionDispatcher } from 'src/modules/app/hooks'
import { resetPageState } from 'src/modules/page/pageSlice'
import { INSTANCE_TYPE_EVENT } from '../app/links'
import {
  DynamicPageTabContext,
  DynamicPageContext,
} from 'src/modules/dynamicPage/dynamicPageContexts'

export const useDynamicPageLoadingHelpers = () => {
  const { linkedPageId, dynamicPagePath } = useParams()
  const dispatch = useDispatch()
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)
  const dispatchUpdateLinkedPageItem = useActionDispatcher(updateLinkedPageItem)
  const dispatchFetchLinkedPageItem = useActionDispatcher(fetchLinkedPageItem)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const handleUpdateLinkedPageitem = photo => {
    dispatchUpdateLinkedPageItem({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      photo,
    })
  }

  const title = fetchedLinkedPage?.title

  const photoUrl = fetchedLinkedPage?.photo?.fileThumbnail
  const headerProps = {
    title: title,
    type: 'website',
    url: window.location.href,
  }

  useEffect(() => {
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    dispatchFetchLinkedPageItem({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      dynamicPagePath,
    })
  }, [dispatchFetchLinkedPageItem, linkedPageId, dynamicPagePath])

  const isLoading = dispatchFetchLinkedPageItem.status === 'loading'

  return {
    handleUpdateLinkedPageitem,
    title,
    headerProps,
    photoUrl,
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    dynamicPagePath,
  }
}

/*

*/
export const useDynamicPageTabHelpers = ({ dynamicPageTypeTabIndex = -1 }) => {
  const dynamicPageContext = useContext(DynamicPageContext)
  const [dynamicPageTab, setDynamicPageTab] = useState()

  useEffect(() => {
    if (dynamicPageContext && dynamicPageTypeTabIndex !== -1) {
      const tabs = dynamicPageContext?.dynamicPageTypeDef?.tabs

      if (tabs) {
        const tab = tabs[dynamicPageTypeTabIndex]
        setDynamicPageTab(tab)
      } else {
        setDynamicPageTab(undefined)
      }
    } else {
      setDynamicPageTab(undefined)
    }
  }, [dynamicPageContext, dynamicPageTypeTabIndex, setDynamicPageTab])

  return {
    dynamicPageId: dynamicPageTab?.dynamicPageTypeDefinition?.id,
    dynamicPageName: dynamicPageTab?.dynamicPageTypeDefinition?.pageName,
    dynamicPageType: dynamicPageTab?.dynamicPageTypeDefinition?.pageType,
    dynamicPageTypeDefinition: dynamicPageTab?.dynamicPageTypeDefinition,
  }
}

export const getDynamicName = (originalName, dynamicName) => {
  return dynamicName || originalName
}

export const useDynamicPageDefFinder = () => {
  const dynamicPageTabContext = useContext(DynamicPageTabContext)
  const dynamicPageContext = useContext(DynamicPageContext)

  const dynamicPageDef =
    dynamicPageTabContext?.dynamicPageTypeDefinition ||
    dynamicPageContext?.dynamicPageTypeDef

  return dynamicPageDef
}

export const useDynamicBackgroundFinder = () => {
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)

  return fetchedLinkedPage?.dynamicPageTypeDef?.backgroundKey
}
