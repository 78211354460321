import React, { useEffect, useRef, useState } from 'react'
import { IconButton } from '../ui'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {
  Box,
  Divider,
  Input,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableTile } from '../photo/EditAlbum'
import PlaceIcon from '@mui/icons-material/Place'
import { AddMediaIcon } from '../writeArticle/PhotoWithFloatingControls'
import { MEDIA_TYPE_PHOTO, SelectMedia, Thumbnail } from '../photo'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { useSelector } from 'react-redux'
import { generateLinkForObject } from 'src/modules/app/links'
import { getWeareFeatureTitle } from './MapSidePanel'

import { LinkFormContainer } from '../ui/editor/InsertLink'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  //INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_MEDIA,
} from 'src/modules/app/links'

const allowLinkTypes = [
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
]

const useStyles = makeStyles(theme => ({
  markerTitle: {
    padding: 0,
    '& .MuiInput-input': {
      color: theme.palette.primary.main,
      ...theme.typography.h4,
    },
  },
  thumbnailImg: {
    width: '100%',
    height: 'auto',
    maxHeight: 400,
  },
}))

const MarkerInput = ({
  weareMapFeature,
  handleSelectLocation,
  handleFocusMarker,
  isSelected,
  handleRemoveMarker,
  dragging,
  dragProps,
  markerIndex,
  handleBlurMarker,
}) => {
  const inputContainerRef = useRef()
  useEffect(() => {
    if (isSelected && !weareMapFeature?.id) {
      const inputs = inputContainerRef?.current?.getElementsByTagName('input')
      inputs[0]?.focus()
    }
  }, [isSelected, weareMapFeature?.id])

  const displayTitle = getWeareFeatureTitle(weareMapFeature)
  console.debug(
    `MarkerInput(): rendering - getWeareFeatureTitle() returned '${displayTitle}' for weareMapFeature`,
    weareMapFeature
  )
  return (
    <Box
      id={`markerInput-${weareMapFeature?.id}`}
      ref={inputContainerRef}
      onClick={() => handleFocusMarker(markerIndex)}
      onBlur={() => handleBlurMarker(markerIndex)}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(204,204,204, 0.4)' : 'transparent',
        padding: 0.5,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
        mt: 1,
        //minWidth: 360,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <DragIndicatorIcon
          size="small"
          style={{
            cursor: dragging ? 'grabbing' : 'grab',
            outline: 'none',
            opacity: weareMapFeature?.id ? 1 : 0,
            pointerEvents: weareMapFeature?.id ? 'auto' : 'none',
          }}
          {...dragProps}
        />
        {
          // weareMapFeature?.title ||
          // weareMapFeature?.address ||
          // // marker?.linkTarget || //TODO linkTarget is removed?
          // weareMapFeature?.target ||
          // weareMapFeature.newFeatureTemporaryTitle
          displayTitle ? (
            <Typography variant="subtitle1">
              {/* {weareMapFeature?.title ||
              //marker?.linkTarget?.display || //TODO linkTarget is removed?
              weareMapFeature?.address ||
              (weareMapFeature?.instanceType === 'address' &&
                weareMapFeature.target.freeText) ||
              (['location', 'event', 'artefact'].includes(
                weareMapFeature?.instanceType
              ) && // a Place / Occasion
                (weareMapFeature.target.title ??
                  weareMapFeature.target.address?.freeText)) ||
              weareMapFeature.newFeatureTemporaryTitle} */}
              {displayTitle}
            </Typography>
          ) : (
            <Typography variant="subtitle1">New item</Typography>
          )
        }
      </Box>

      <IconButton
        style={{ marginLeft: 'auto' }} // leave a gap so this is on the right edge
        permissionAction={ACTION_ALL_ACCESS}
        handleSubmit={e => {
          e.stopPropagation()
          handleRemoveMarker(weareMapFeature)
        }}
      >
        <RemoveCircleIcon color="primary" size="small" />
      </IconButton>
    </Box>
  )
}

const SortableMarkers = ({
  locations,
  handleRemoveMarker,
  onMarkersUpdated,
  handleSelectLocation,
  handleFocusMarker,
  handleBlurMarker,
  currentMarker,
  setCurrentMarkerIndex,
}) => {
  const [idDragging, setIdDragging] = useState(null)

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(sensor)

  const handleDragEnd = async event => {
    const { active, over } = event
    setIdDragging(null)

    if (active.id !== over.id) {
      const oldIndex = locations.findIndex(({ id }) => id === active.id)
      const newIndex = locations.findIndex(({ id }) => id === over.id)
      setCurrentMarkerIndex(newIndex)
      const newMarkers = arrayMove(locations, oldIndex, newIndex)
      onMarkersUpdated(newMarkers)
    }
  }
  return (
    <Box
      id="sortableMarkers"
      sx={{
        //flexGrow: 1,
        //overflowY: 'auto',
        // ml: 2,
        width: '100%',
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => {
          setIdDragging(active.id)
        }}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setIdDragging(null)}
      >
        <SortableContext items={locations} strategy={rectSortingStrategy}>
          <Stack
            direction="column"
            sx={{
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {locations?.map((weareMapFeature, index) => {
              if (weareMapFeature?.id) {
                return (
                  <SortableTile
                    id={weareMapFeature.id}
                    key={weareMapFeature.id}
                    dragging={idDragging === weareMapFeature.id}
                  >
                    <MarkerInput
                      weareMapFeature={weareMapFeature}
                      handleSelectLocation={handleSelectLocation}
                      handleFocusMarker={handleFocusMarker}
                      handleBlurMarker={handleBlurMarker}
                      isSelected={Boolean(
                        currentMarker?.id === weareMapFeature?.id
                      )}
                      handleRemoveMarker={handleRemoveMarker}
                      dragging={idDragging === weareMapFeature.id}
                      markerIndex={index}
                    />
                  </SortableTile>
                )
              } else {
                return (
                  <MarkerInput
                    weareMapFeature={weareMapFeature}
                    handleSelectLocation={handleSelectLocation}
                    handleFocusMarker={handleFocusMarker}
                    handleBlurMarker={handleBlurMarker}
                    isSelected={Boolean(
                      currentMarker?.id === weareMapFeature?.id
                    )}
                    handleRemoveMarker={handleRemoveMarker}
                    markerIndex={index}
                  />
                )
              }
            })}
          </Stack>
        </SortableContext>
      </DndContext>
    </Box>
  )
}

/**
 *  Shows a re-orderable list of features in the map, and at the bottom of the list a form
 * to edit the selected feature
 */
const MarkerEditor = ({
  //currentMap,
  setLocations,
  locations,
  //editableMapTitle,
  //setMapTitle,
  currentMarker,
  setCurrentMarkerIndex,
  flyToEditMode,
  dropPinMode,
  setDropPinMode,
  offerLinkSelector,
}) => {
  console.debug(`MarkerEditor(): rendering with locations`, locations)
  console.debug(`MarkerEditor(): rendering with currentMarker`, currentMarker)
  const classes = useStyles()

  const handleAddress = (name, formattedPlace) => {
    let firstWord = formattedPlace.split(' ')[0]
    if (firstWord.at(-1) === ',') {
      firstWord = firstWord.substring(0, firstWord.length - 1)
    }

    if (firstWord !== name) {
      return `${name}${formattedPlace ? ', ' + formattedPlace : ''}`
    } else return `${formattedPlace}`
  }
  const updateCurrentMarker = updates => {
    setLocations(prevState => {
      const locationsArr = [...prevState]

      const markerIndex = locationsArr.findIndex(
        item => item.id === currentMarker.id
      )

      locationsArr[markerIndex] = {
        ...locationsArr[markerIndex],
        ...updates,
      }
      return [...locationsArr]
    })
  }

  const handleSelectLocation = location => {
    const properties = location?.properties
    const coords = properties?.coordinates
    let newMarker = {
      lat: coords.latitude,
      lng: coords.longitude,
      address: handleAddress(properties?.name, properties.place_formatted),
    }
    if (!currentMarker?.id) {
      newMarker = {
        ...newMarker,
        id: `SORTABLE-ID-${Math.random()}-${coords.lat}-${
          coords.lon
        }-${Math.random()}`,
        title: properties.name,
        icon: PlaceIcon,
      }
      setLocations(prevState => [
        ...prevState.filter(
          locationItem => locationItem.lat && locationItem.lng
        ),
        newMarker,
      ])
    } else {
      newMarker = {
        ...currentMarker,
        ...newMarker,
        marker: null,
        title: !currentMarker?.title ? properties.name : currentMarker?.title,
      }
      if (currentMarker?.marker) {
        currentMarker.marker.remove()
      }
      updateCurrentMarker(newMarker)
    }
    flyToEditMode(newMarker)
  }

  const handleEditCurrentMarker = e => {
    const value = e.target.value
    const name = e.target.name
    const updates = {
      [name]: value,
    }
    updateCurrentMarker(updates)
  }

  const handleRemoveMarker = marker => {
    if (marker?.marker) {
      marker.marker.remove()
    }
    setLocations(prevState => {
      const newLocations = prevState.filter(
        location => marker.id !== location.id
      )

      return [...newLocations]
    })
    if (currentMarker?.id === marker?.id) {
      setCurrentMarkerIndex(-1)
    }
  }

  // useEffect(() => {
  //   if (!editableMapTitle) {
  //     setMapTitle(currentMap?.title)
  //   }
  // }, [currentMap, editableMapTitle, setMapTitle])

  const handleFocusMarkerInput = markerIndex => {
    setCurrentMarkerIndex(markerIndex)
    if (locations[markerIndex]?.id) {
      flyToEditMode(locations[markerIndex])
      if (dropPinMode) {
        setDropPinMode(false)
      }
    }
  }

  const handleBlurMarker = markerIndex => {
    if (dropPinMode && !locations[markerIndex]?.id) {
      setDropPinMode(false)
    }
  }

  const handleAddMedia = selectedMedia => {
    const updates = {
      photo: selectedMedia,
    }
    updateCurrentMarker(updates)
  }
  const handleClearMedia = () => {
    const updates = {
      photo: null,
    }
    updateCurrentMarker(updates)
  }

  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  const generateTargetUrl = currentMarker => {
    if (
      currentMarker &&
      (currentMarker.instanceType ?? currentMarker.target?.type) &&
      (currentMarker.target?.id ?? currentMarker.instanceId)
    ) {
      let url
      if (authorisedTreeSlug) {
        url = generateLinkForObject(
          authorisedTreeSlug,
          currentMarker.instanceType ?? currentMarker.target?.type,
          currentMarker.target?.id ?? currentMarker.instanceId
        )
      }

      console.debug(`MarkerEditor.generateTargetUrl(): returning url '${url}'`)
      return url
    }
  }

  const existingText =
    currentMarker?.display ??
    currentMarker?.target?.display ??
    currentMarker?.target?.title
  const existingLink = !currentMarker
    ? null
    : currentMarker?.target
    ? {
        instanceType: currentMarker.instanceType ?? currentMarker.target?.type,
        display: existingText,
        title: currentMarker.target.title,
        id: currentMarker.target.id ?? currentMarker.instanceId,
      }
    : currentMarker.instanceId && currentMarker.instanceType
    ? {
        id: currentMarker.instanceId,
        instanceType: currentMarker.instanceType,
      }
    : null
  const existingWebLink = null

  const onLinkSubmitted = async data => {
    console.debug(`MarkerEditor.onLinkSubmitted(): data`, data)
    if (data && data.link) {
      const target = {
        id: data.link.id,
        //instanceType: data.link.instanceType,
        title: data.link.display,
        photo: data.link.previewThumbnail?.fileThumbnail
          ? {
              fileThumbnail: data.link.previewThumbnail?.fileThumbnail,
              //fileMedium:
            }
          : undefined,
      }

      const newMarkerData = {
        //id: data.id
        instanceType: data.link.instanceType,
        target: target,
      }
      console.debug(
        `MarkerEditor.onLinkSubmitted(): calling updateCurrentMarker()... newMarkerData:`,
        newMarkerData
      )

      // calls setLocations()
      await updateCurrentMarker(newMarkerData)

      console.debug(
        `MarkerEditor.onLinkSubmitted(): currentMarker updated to`,
        currentMarker
      )
    } else {
      // user probably cancelled the selection.

      const newMarkerData = {
        instanceType: null,
        target: null,
        display: null,
      }

      // calls setLocations()
      await updateCurrentMarker(newMarkerData)

      console.debug(
        `MarkerEditor.onLinkSubmitted(): currentMarker updated to`,
        currentMarker
      )
    }
  }

  const currentTargetUrl = generateTargetUrl(currentMarker)

  console.debug(
    `MarkerEditor(): rendering with currentTargetUrl: ${currentTargetUrl}`
  )
  return (
    <>
      <Box
        id="markerEditor"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SortableMarkers
          handleRemoveMarker={handleRemoveMarker}
          locations={locations}
          onMarkersUpdated={setLocations}
          setCurrentMarkerIndex={setCurrentMarkerIndex}
          handleSelectLocation={handleSelectLocation}
          handleFocusMarker={handleFocusMarkerInput}
          currentMarker={currentMarker}
          handleBlurMarker={handleBlurMarker}
        />
      </Box>
      {currentMarker?.id && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box px={2}>
            <form>
              {
                // only offer a title if marker already has one or the item is a shape
              }
              {(currentMarker?.title ||
                currentMarker?.newFeatureTemporaryTitle) && (
                <Box mb={1}>
                  <Typography>Title: </Typography>
                  <Input
                    className={classes.markerTitle}
                    name="title"
                    variant="filled"
                    value={currentMarker?.title || ''}
                    placeholder="Add a title..."
                    onChange={handleEditCurrentMarker}
                    fullWidth
                  />
                </Box>
              )}
              <Box mt={1} sx={{ width: '100%' }}>
                {currentMarker.target?.photo?.fileThumbnail ? (
                  <Thumbnail
                    {...{
                      className: classes.thumbnailImg,
                      file: currentMarker.target.photo.fileThumbnail,
                      withEditMedia: false,
                      //...props,
                      onClear: params => {
                        // call handleSelect with a null photo to clear it
                        handleClearMedia(null)
                      },
                    }}
                  />
                ) : (
                  //</Link>
                  <>
                    {currentMarker?.photo ? (
                      <SelectMedia
                        aspectRatio={1}
                        onSelect={handleAddMedia}
                        onSelectCropped={handleAddMedia}
                        cropAfterSelect={true}
                        mediaType={MEDIA_TYPE_PHOTO}
                        trigger={props => (
                          <Thumbnail
                            {...{
                              className: classes.thumbnailImg,
                              file: currentMarker?.photo?.fileMedium,
                              withEditMedia: true,
                              ...props,
                              onClear: params => {
                                // call handleSelect with a null photo to clear it
                                handleClearMedia(null)
                              },
                            }}
                          />
                        )}
                      />
                    ) : (
                      !currentMarker.target ||
                      (currentMarker.instanceType === 'address' && (
                        <AddMediaIcon
                          handleSelect={handleAddMedia}
                          media
                          //height={300}
                          //width={'100%'}
                          hideTags={true}
                          defaultAllowNoTags={true}
                          mediaType={MEDIA_TYPE_PHOTO}
                          onSelectCropped={handleAddMedia}
                          cropAfterSelect={true}
                          placeholderStyles={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            flexDirection: 'row',
                          }}
                        />
                      ))
                    )}
                  </>
                )}
              </Box>

              {currentTargetUrl &&
              (!currentMarker.target ||
                currentMarker.instanceType === 'address') ? (
                <>
                  <Typography mt={2}>Marker Description: </Typography>

                  <TextField
                    multiline
                    variant="outlined"
                    name="description"
                    rows={10}
                    value={currentMarker?.description || ''}
                    placeholder="Add a description..."
                    onChange={handleEditCurrentMarker}
                    fullWidth
                  />
                </>
              ) : null}
              {offerLinkSelector && (
                <LinkFormContainer
                  key={currentMarker.id ?? currentMarker.target.id}
                  link={existingLink}
                  onSubmit={onLinkSubmitted}
                  text={existingText}
                  url={existingWebLink}
                  showTextField={false}
                  buttonLabelCreate="Save"
                  buttonLabelUpdate="Save"
                  secondaryButtonLabel={undefined}
                  //secondaryButtonValue={undefined}
                  onClickSecondaryButton={undefined}
                  autoSubmit={true}
                  hideListHeader={false}
                  filterToInstanceTypes={allowLinkTypes}
                  allowWebLink={false}
                />
              )}
              {currentMarker.target &&
                currentMarker.instanceType !== 'address' && (
                  <>
                    {currentMarker.target.photo?.thumbnailUrl && (
                      <Thumbnail
                        {...{
                          className: classes.thumbnailImg,
                          file: currentMarker.target.photo.thumbnailUrl,
                          withEditMedia: false,
                        }}
                      />
                    )}
                    {/* {currentTargetUrl && (
                      <Link
                        to={currentTargetUrl}
                        key={currentMarker.target.id}
                        target="_blank"
                      >
                        Open{' '}
                        {getTypeDisplayText(
                          currentMarker.target?.type ||
                            currentMarker.instanceType
                        )}{' '}
                        in a new tab
                      </Link>
                    )} */}
                  </>
                )}
            </form>
          </Box>
        </>
      )}
    </>
    // </Box>
  )
}

export default MarkerEditor
