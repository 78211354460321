import { useDynamicPageLoadingHelpers } from './dynamicPageHooks'
import { LoadingIndicator } from 'src/modules/ui'
import { INSTANCE_TYPE_EVENT } from '../app/links'
import { LinkedPageDisplay } from '../page/LinkedPage'
import { createRoute } from '../page/Page'
import { DynamicPageContext } from './dynamicPageContexts'

const DynamicPage = () => {
  const {
    isLoading,
    fetchedLinkedPage,
    linkedPageId,
    treeSlug,
    handleUpdateLinkedPageitem,
  } = useDynamicPageLoadingHelpers()

  if (isLoading) {
    return (
      <>
        <LoadingIndicator />
      </>
    )
  }

  const routes = []

  fetchedLinkedPage?.dynamicPageTypeDef?.tabs.forEach((tab, index) => {
    const createTabRoute = createRoute({
      exact: tab.exact,
      routeType: tab.componentKey,
      navKey: tab.componentKey,
      linkedPagePathOverride:
        fetchedLinkedPage?.dynamicPageTypeDef.pageTypePath,
      routePathOverride: tab.dynamicPageTypeDefinition?.pageTypePath,
    })

    const route = createTabRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
      localLabelOverride: tab.tabName,
      makeDefault: tab.makeDefault,
    })

    //add the tab index to the route so we can sish out the tab data down in the tree.
    route.componentParams = {
      dynamicPageTypeTabIndex: index,
    }

    routes.push(route)
  })

  return (
    <DynamicPageContext.Provider
      value={{
        dynamicPageId: fetchedLinkedPage?.dynamicPageTypeDef?.id,
        dynamicPageTypeDef: fetchedLinkedPage?.dynamicPageTypeDef,
      }}
    >
      <LinkedPageDisplay
        handleUpdateLinkedPageitem={handleUpdateLinkedPageitem}
        fetchedLinkedPage={fetchedLinkedPage}
        routes={routes}
        linkedPageId={linkedPageId}
        pageType={INSTANCE_TYPE_EVENT}
      />
    </DynamicPageContext.Provider>
  )
}

export default DynamicPage
