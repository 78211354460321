import { styled } from '@mui/material'
import { isUndefined } from 'lodash'
import React from 'react'

import { useBackgroundImage } from 'src/utils'
import { selectLinkedPageItem } from 'src/modules/page/pageSlice'
import { useSelector } from 'react-redux'

const Root = styled('div', {
  shouldForwardProp: prop => !['sizeSm', 'minHeight'].includes(prop),
})(({ theme, sizeSm, minHeight }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.maxWidth,
  margin: '0 auto',
  minHeight: !isUndefined(minHeight) ? minHeight : 'calc(100vh - 85px)',
  padding: theme.spacing(0, 2),
  ...(sizeSm && {
    maxWidth: 600,
  }),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(1.5),
  },
}))

const Background = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})(({ theme, backgroundImage }) => ({
  backgroundImage,
  backgroundPosition: `center -${theme.headerHeight}px`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
}))

const Container = ({
  children,
  maxWidth,
  prePadding,
  title,
  fullWidthSubRootContainer = false,
  showBackGround = true,
  ...props
}) => {
  const backgroundImage = useBackgroundImage()
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)

  console.log('DEBUG Container ', fetchedLinkedPage)

  return (
    <Background backgroundImage={showBackGround ? backgroundImage : null}>
      {title && (
        <Root sizeSm={maxWidth === 'sm'} minHeight={0}>
          {title}
        </Root>
      )}
      {prePadding}
      {fullWidthSubRootContainer ? (
        <>{children}</>
      ) : (
        <Root sizeSm={maxWidth === 'sm'} {...props}>
          {children}
        </Root>
      )}
    </Background>
  )
}

export default Container
