import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import { isUndefined } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'
import { fetchLinkedPages, selectLinkedPages } from 'src/modules/page/pageSlice'

import { selectIndividualById } from '../viewer/viewerSlice'
import BasePageList from './BasePageList'
import Refresh from '../ui/Refresh'
import { ViewConfigSelectorContainer } from './ViewConfigSelectorContainer'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../common/viewConfigUtils'

import { selectLinkedPageLastUpdated, setViewConfig } from './pageSlice'
import AddLinkedPageControl from './AddLinkedPageControl'
import { useDynamicPageDefFinder } from '../dynamicPage/dynamicPageHooks'

const LinkedPageList = ({
  action,
  selector,
  canCreateItem = true,
  target,
  type,
  queryAsSubject = false,
  map,
  initialShowMap = false,
  fetchPageSize,
}) => {
  const lastUpdated = useSelector(selectLinkedPageLastUpdated)
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatch = useDispatch()
  const dispatchFetchLinkedPages = useActionDispatcher(
    action ? action : fetchLinkedPages
  )
  const dynamicPageDef = useDynamicPageDefFinder()

  const linkedPages = useSelector(selector ? selector : selectLinkedPages)
  const { next, results } = linkedPages
  const makeArgs = useCallback(
    page => {
      const args = { page, type, viewConfigQueryParams }
      if (queryAsSubject) {
        args.subject = target
        args.dynamicPageDefId = dynamicPageDef?.id
      } else {
        args.target = target
        args.dynamicPageDefId = dynamicPageDef?.id
      }
      if (fetchPageSize) {
        args.pageSize = fetchPageSize
      }
      return args
    },
    [
      queryAsSubject,
      target,
      type,
      viewConfigQueryParams,
      fetchPageSize,
      dynamicPageDef,
    ]
  )

  console.log('DEBUG LinkedPageList', dynamicPageDef, makeArgs(0))

  // For creation of entities linked to individuals, preselect the
  // family as well:
  const targets = [target]
  const individual = useSelector(selectIndividualById(target))
  if (!isUndefined(individual)) {
    targets.push(individual.family)
  }

  useEffect(() => {
    const fetchLinkedPages = async () => {
      await dispatchFetchLinkedPages(makeArgs(0))
    }
    fetchLinkedPages()
  }, [dispatchFetchLinkedPages, makeArgs, type, target, lastUpdated])

  const refresh = () => {
    dispatchFetchLinkedPages(makeArgs(0))
  }

  const handleFetchMore = () => {
    if (dispatchFetchLinkedPages.status === 'loading') {
      return
    }
    dispatchFetchLinkedPages(makeArgs(linkedPages.page + 1))
  }

  const { sort, hierarchical, ancestralOnly, showMap } = getViewConfig(
    linkedPages,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setViewConfig({ type, viewConfig }))
    dispatchFetchLinkedPages(makeArgs(0))
  }

  return (
    <BasePageList
      // actions prop should be an element not a function otherwise it keeps getting re-mounted
      actions={
        canCreateItem &&
        results.length > 0 && (
          <Actions
            targets={targets}
            onRefreshRequired={refresh}
            onSetViewConfig={handleSetViewConfig}
            sort={sort}
            hierarchical={hierarchical}
            ancestralOnly={ancestralOnly}
            type={type}
            target={target}
            mapAvailable={!!map}
            showMap={showMap}
          />
        )
      }
      {...{
        dispatchFetchPage: dispatchFetchLinkedPages,
        next,
        handleFetchMore,
        pages: linkedPages,
        results,
        type,
      }}
      map={map}
      showMap={showMap}
    />
  )
}

export const ActionsContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(2),
}))

export const Actions = ({
  type,
  onRefreshRequired,
  targets,
  onSetViewConfig,
  sort,
  hierarchical,
  ancestralOnly,
  target,
  viewConfigSelectContainer = ViewConfigSelectorContainer,
  mapAvailable,
  showMap,
}) => {
  const ViewConfigSelectContainerComponent = viewConfigSelectContainer

  return (
    <ActionsContainer>
      <Box style={{ display: 'flex' }}>
        <ViewConfigSelectContainerComponent
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={onSetViewConfig}
          target={target}
          mapAvailable={mapAvailable}
          showMapValue={showMap}
        />
        {/*{mapAvailable && (*/}
        {/*  <>*/}
        {/*    <Typography*/}
        {/*      variant="body3"*/}
        {/*      display="block"*/}
        {/*      sx={{ marginLeft: 2, fontWeight: 500 }}*/}
        {/*    >*/}
        {/*      <>*/}
        {/*        Show map*/}
        {/*        <Checkbox*/}
        {/*          label="Show map"*/}
        {/*          checked={showMap}*/}
        {/*          onChange={e => onSetShowMap(!showMap)}*/}
        {/*        />{' '}*/}
        {/*      </>*/}
        {/*    </Typography>*/}
        {/*  </>*/}
        {/*)}*/}
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <AddLinkedPageControl targets={targets} type={type} />
        <Refresh onClick={onRefreshRequired} />
      </Box>
    </ActionsContainer>
  )
}

export default LinkedPageList
