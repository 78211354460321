import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPageState } from 'src/modules/page/pageSlice'
import { resetViewer } from 'src/modules/viewer/viewerSlice'
import isFunction from 'lodash/isFunction'
import { resetHomePageState } from 'src/modules/home/homeSlice'
import {
  setTreeSlugAndFail,
  selectUser,
  updateProfile,
  setTreeLoading,
} from 'src/modules/auth/authSlice'
import {
  generateBlogHomeLink,
  generateBlogLinkFromPreview,
  generateEmbedLinkForPublicRoot,
  generateEmbedLinkForPublicShareRoot,
  generateLinkForPublicRoot,
  generateLinkForPublicShareRoot,
  generatePublicBlogLink,
  generateTreeHomeLink,
  INSTANCE_TYPE_FOR_PATH_SEGMENT,
  PATH_SEGMENT_FAMILY_PROFILE,
  PATH_SEGMENT_INDIVIDUAL_PROFILE,
  PUBLIC_ROOT,
  PUBLIC_SHARE,
} from './links'
import { fetchImageAccessCookie } from 'src/modules/viewer/viewerSlice'
import { resetExploreTreeState } from '../viewer/exploreTreeSlice'
import copy from 'copy-to-clipboard'
import { BLOG_TREE } from '../common/constants'
import {
  selectCurrentTree,
  selectAuthorisedTreeSlug,
  selectIsBlogTree,
} from '../auth/authSlice'
import { getQueryParams } from '../../utils'
import { useAvailableFeatures, useIsAlphaFeatureUser } from '../auth/hooks'
import {
  fetchIndividualsForPyramid,
  selectIsNodeDirectoryIsLoaded,
} from '../viewer/viewerSlice'
import { resetAlbumToInitialState } from '../photo/photoSlice'
import TwoLineInfoSnackBar from './TwoLineInfoSnackBar'
import {
  fetchArticleTemplates,
  notificationTimerStart,
  selectArticleTemplates,
} from './appSlice'
import { useGetThenHandleNotifications } from './notificationHandlers'
import { setHomeViewConfigDefaults } from '../home/homeSlice'
import {
  FOCUS_FAMILY_ARCHIVE,
  FOCUS_ONE_PLACE_STUDY,
  FOCUS_OCCASION_ARCHIVE,
} from './appConstants'
import { selectTree } from '../public/tree/treeSlice'

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const useArchiveFocus = () => {
  const location = useLocation()

  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search)
  const focus = queryParams.get('focus')

  return focus
}

export const useAppendQueryParams = () => {
  const location = useLocation()
  const queryString = location.search

  const appendQueryParams = useCallback(
    url => {
      const newUrl = `${url}${queryString}`
      return newUrl
    },
    [queryString]
  )

  return appendQueryParams
}

export const usePublicTreeSettings = () => {
  const tree = useSelector(selectTree)
  const treeSettings = useTreeSettings({
    treeOverride: tree,
    lastUrlPathIndex: 4,
  })
  return treeSettings
}

export const useTreeSettings = ({
  treeOverride,
  lastUrlPathIndex = 3,
} = {}) => {
  const currentTree = useSelector(selectCurrentTree)
  const settingsTree = treeOverride || currentTree

  const location = useLocation()

  // Extract the last part of the URL
  const pathSegments = location.pathname.split('/')
  const lastUrlSegment = pathSegments[lastUrlPathIndex]
  const homeInstanceId = settingsTree?.treeSettings?.homeInstanceId
  const homeInstanceModel = settingsTree?.treeSettings?.homeInstanceModel
  const homeInstanceTitle = settingsTree?.treeSettings?.homeInstanceTitle
  const archiveFocus = settingsTree?.treeSettings?.focus || FOCUS_FAMILY_ARCHIVE

  let showFamilyPyramid = true
  let showNavigator = true
  let isOnePlaceStudyArchive = false

  if (homeInstanceId && homeInstanceModel) {
    showFamilyPyramid = false
    showNavigator = false
    isOnePlaceStudyArchive = true
  }

  let getTreeFocusHumanReadable = useCallback(
    treeOverride => {
      const tree = treeOverride || settingsTree
      if (tree?.treeSettings?.focus === FOCUS_ONE_PLACE_STUDY) {
        return 'One Place Study'
      } else {
        return 'Family Archive'
      }
    },
    [settingsTree]
  )

  //TODO rename this to special archive home
  const isOnePlaceStudyHome = useCallback(() => {
    return (
      (archiveFocus === FOCUS_ONE_PLACE_STUDY ||
        archiveFocus === FOCUS_OCCASION_ARCHIVE) &&
      lastUrlSegment === homeInstanceId
    )
  }, [archiveFocus, lastUrlSegment, homeInstanceId])

  return {
    homeInstanceTitle,
    homeInstanceId,
    homeInstanceModel,
    archiveFocus,
    showFamilyPyramid,
    showNavigator,
    lastUrlSegment,
    isOnePlaceStudyArchive,
    isOnePlaceStudyHome,
    getTreeFocusHumanReadable,
  }
}

/**
 * Defines the sequence of actions needed to start operating on a
 * particular tree.
 */
export const useSetTree = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug) // from authSlice - null if the tree passed to setTreeSlug() is not in the user's authorised tree list
  const isNodeDirectoryLoaded = useSelector(selectIsNodeDirectoryIsLoaded)

  const dispatchFetchImageAccessCookie = useActionDispatcher(
    fetchImageAccessCookie,
    { errorNotification: false }
  )
  const dispatchUpdateProfile = useActionDispatcher(updateProfile)

  const isExportMode = useIsAlphaFeatureUser()?.exportMode

  const getThenHandleNotifications = useGetThenHandleNotifications()

  //called from:
  //  setTreeAndNavigate()
  //  TreeApp useEffect when slug changes
  //  CompleteProfile submit
  //  onboarding if lastViewedTree is set
  //  ChangeHomePersonButton
  //  CreateOrUpdateTree after creating new tree
  //  ManageTrees.navigateToRootTree()
  //  SelectMyself if the user is an individual on the tree
  //  TreeSelector.handleSelect()
  //  UpdateBlogName.handleDoneNaming()
  //  EditTree.handleDoneNaming()
  //  EditTree.handleDoneEditing()
  const setTree = useCallback(
    async (treeSlug, forceSetTree) => {
      if (
        treeSlug !== authorisedTreeSlug ||
        !isNodeDirectoryLoaded ||
        forceSetTree
      ) {
        await dispatch(setTreeLoading(true))
        await dispatch(resetHomePageState())
        await dispatch(resetPageState())
        await dispatch(resetViewer())
        await dispatch(resetExploreTreeState())
        await dispatch(resetAlbumToInitialState())

        try {
          await dispatch(setTreeSlugAndFail(treeSlug)) // calls updateUserAttributesFromTree() which sets authorisedTreeSlug
          await dispatch(setHomeViewConfigDefaults({ treeSlug }))
        } catch (err) {
          if (err.message === 'Access denied to default tree') {
            // redirect to the user's list of trees so they can select one or create a new one
            history.push('/tree-admin')
          } else {
            // redirect to the user's default (last viewed) tree
            history.push('/')
          }
          return
        }

        if (!treeSlug) {
          // User trying to go to null tree
          // Force redirect to default tree
          // it's going to happen below anyway when dispatchFetchImageAccessCookie
          // is called with null slug
          history.push('/')
          return
        }

        try {
          await dispatchFetchImageAccessCookie({ treeSlug }).unwrap()
          if (treeSlug && !isExportMode) {
            await dispatchUpdateProfile({ setLastViewedTree: treeSlug })
          }
        } catch (err) {
          console.error(
            `hooks.useSetTree.setTree(): error from await dispatchFetchImageAccessCookie():`,
            err
          )
          // Force redirect to default tree
          history.push('/')
          return
        }
        if (!isExportMode) {
          dispatch(
            fetchIndividualsForPyramid({
              treeSlug,
            })
          )

          //start a timer to poll for notifications, and do an initial call
          dispatch(
            // in appSlice.js
            notificationTimerStart({
              getThenHandleNotifications: getThenHandleNotifications,
            })
          )
          getThenHandleNotifications()
        }

        await dispatch(setTreeLoading(false))
      }
    },
    [
      dispatch,
      dispatchFetchImageAccessCookie,
      dispatchUpdateProfile,
      history,
      authorisedTreeSlug,
      isNodeDirectoryLoaded,
      isExportMode,
      getThenHandleNotifications,
    ]
  )

  return setTree
}

export const useSetTreeAndNavigate = () => {
  const setTree = useSetTree()
  const history = useHistory()

  const setTreeAndNavigate = useCallback(
    async (treeSlug, treeType) => {
      await setTree(treeSlug)
      if (treeType === BLOG_TREE) {
        history.push(generateBlogHomeLink(treeSlug))
      } else {
        history.push(generateTreeHomeLink(treeSlug, treeType))
      }
    },
    [history, setTree]
  )
  return setTreeAndNavigate
}

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const defaultError = 'Ooops there was an error doing that'

  const showError = useCallback(
    (message, options = {}) => {
      enqueueSnackbar(message || defaultError, { variant: 'error', ...options })
    },
    [enqueueSnackbar]
  )
  const showSuccess = useCallback(
    (message, options = {}) => {
      enqueueSnackbar(message, { variant: 'success', ...options })
    },
    [enqueueSnackbar]
  )

  const showInfoMessage = useCallback(
    (message, options = {}) => {
      const closeSnackbarAndCallCallback = id => {
        closeSnackbar(id)
        if (options?.closeSnackbar) {
          options.closeSnackbar(id)
        }
      }

      const newOptions = {
        content: (key, message) => {
          return (
            <TwoLineInfoSnackBar
              id={key}
              closeSnackbar={closeSnackbarAndCallCallback}
              message={message}
              secondMessage={options?.secondMessage}
              secondMessageAction={options?.secondMessageAction}
              action={options?.action}
            />
          )
        }, // enqueueSnackbar() seems to copy option properties to DOM item attributes, so remove our ones that aren't needed any more
        ...(({ secondMessage, secondMessageAction, closeSnackbar, ...o }) => o)(
          options
        ),
      }

      enqueueSnackbar(message, newOptions)
    },
    [enqueueSnackbar, closeSnackbar]
  )

  return {
    showSuccess,
    showError,
    showInfoMessage,
  }
}

export const useActionDispatcher = (action, options = {}) => {
  const { initialStatus = 'idle' } = options

  const dispatch = useDispatch()
  const [currentRequestId, setCurrentRequestId] = useState()
  const [abortedRequestId, setAbortedRequestId] = useState()
  const [status, setStatus] = useState(initialStatus)
  const [error, setError] = useState()
  const { showError, showSuccess } = useNotification()

  // Handle setting status to aborted only if latest aborted
  useEffect(() => {
    if (
      abortedRequestId &&
      currentRequestId &&
      abortedRequestId === currentRequestId
    ) {
      setStatus('aborted')
    }
  }, [abortedRequestId, currentRequestId])

  const dispatchAction = useCallback(
    (arg, options = {}) => {
      // set errorNotification == false to disable error notification
      const { errorNotification, successNotification, errorSnackbarOptions } =
        options

      setStatus('loading')
      setError()

      const handleSuccess = payload => {
        setStatus('fulfilled')

        // Notification
        if (isFunction(successNotification)) {
          const notificationText = successNotification(payload)
          showSuccess(notificationText)
        } else if (successNotification) {
          showSuccess(successNotification)
        }
      }

      const handleFailure = err => {
        // Notification
        if (err.name === 'AbortError') {
          setAbortedRequestId(result.requestId)
          return
        }

        setStatus('error')
        setError(err)
        console.error(err)
        console.trace()

        if (isFunction(errorNotification)) {
          const notificationText = errorNotification(err)
          showError(notificationText, errorSnackbarOptions)
        } else if (errorNotification) {
          showError(errorNotification, errorSnackbarOptions)
        }
      }

      // We can't use async/await here as we want to return the raw
      // promise returns from the action as createAsyncThunk actions
      // return a custom promis with extra actions
      const result = dispatch(action(arg))
      setCurrentRequestId(result.requestId)
      result.unwrap().then(handleSuccess).catch(handleFailure)
      return result
    },
    [
      dispatch,
      action,
      showError,
      showSuccess,
      setCurrentRequestId,
      setAbortedRequestId,
    ]
  )

  dispatchAction.status = status
  dispatchAction.error = error

  return dispatchAction
}

export const useShare = (fromPublic, urlOverride) => {
  const { showSuccess } = useNotification()
  const location = useLocation()
  const isBlogTree = useSelector(selectIsBlogTree)
  const { blogPreview, treeOverride } = getQueryParams(location)

  const handleShare = (
    privateTreeShare = false,
    queryParams = {},
    hideNotification = false
  ) => {
    let treeSlug = location.pathname

    let queryString = Object.keys(queryParams)
      .map(
        key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join('&')
    queryString = queryString ? '?' + queryString : ''

    if (urlOverride) {
      copy(urlOverride)
    } else {
      if (privateTreeShare) {
        const url = generateLinkForPublicShareRoot(location.pathname)
        copy(`${url}${queryString}`)
      } else if (!fromPublic) {
        if (treeSlug.includes(PATH_SEGMENT_FAMILY_PROFILE)) {
          treeSlug = treeSlug.replace(PATH_SEGMENT_FAMILY_PROFILE, 'welcome')
        } else if (treeSlug.includes(PATH_SEGMENT_INDIVIDUAL_PROFILE)) {
          treeSlug = treeSlug.replace(
            PATH_SEGMENT_INDIVIDUAL_PROFILE,
            'welcome'
          )
        }
        if (isBlogTree) {
          treeSlug = treeSlug.split('/')[1]
          copy(generatePublicBlogLink(treeSlug))
        } else {
          const url = generateLinkForPublicRoot(treeSlug)
          copy(`${url}${queryString}`)
        }
      } else {
        if (blogPreview) {
          if (treeOverride) {
            let blogLink = generateBlogLinkFromPreview(treeOverride, treeSlug)
            copy(blogLink)
          } else {
            copy(window?.location?.href?.split('?')?.[0])
          }
        } else {
          copy(window.location)
        }
      }
    }
    if (!hideNotification) showSuccess('Copied url to clipboard')
  }

  return handleShare
}

export const useShareEmbed = fromPublic => {
  const { showSuccess } = useNotification()
  const location = useLocation()

  const handleEmbedShare = (privateTreeShare = false) => {
    if (privateTreeShare) {
      const url = generateEmbedLinkForPublicShareRoot(location.pathname)
      copy(url)
    } else if (!fromPublic) {
      const url = generateEmbedLinkForPublicRoot(location.pathname)
      copy(url)
    } else {
      console.error('Cannot share from this location')
      return
    }
    showSuccess('Copied url to clipboard')
  }

  return handleEmbedShare
}

export const useHover = () => {
  const [value, setValue] = useState(false)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (ref.current) {
      ref.current.removeEventListener('mouseover', handleMouseOver)
      ref.current.removeEventListener('mouseout', handleMouseOut)
    }
    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)
    }
    ref.current = node
  }, [])

  return [setRef, value]
}

export const useCorsImageProps = () => {
  const user = useSelector(selectUser)
  return !!user ? { crossOrigin: 'anonymous' } : {}
}

export const useUrlTypeFromUrl = () => {
  const location = useLocation()
  if (location.pathname.includes(PUBLIC_SHARE)) {
    return PUBLIC_SHARE
  } else if (location.pathname.includes(PUBLIC_ROOT)) {
    return PUBLIC_ROOT
  } else {
    return null
  }
}

export const useAskFamilyButton = () => {
  const tree = useSelector(selectCurrentTree)
  const location = useLocation()
  const { treeTypeOverride } = getQueryParams(location)
  let showButton = true

  const isExportMode = useIsAlphaFeatureUser()?.exportMode

  if (
    ((tree?.treeType === BLOG_TREE || treeTypeOverride === BLOG_TREE) &&
      location?.pathname?.includes('write-article')) ||
    isExportMode
  ) {
    showButton = false
  }

  return showButton
}

export const useGetSubscription = () => {
  const user = useSelector(selectUser)
  const { subscriptions } = user

  if (subscriptions?.length > 1) {
    const vip = subscriptions?.find(
      sub => !sub?.currentSubscriptionPeriodEndDate && sub?.cancelAtPeriodEnd
    )
    if (vip) {
      return vip
    }

    const paying = subscriptions?.find(
      sub => sub?.currentSubscriptionPeriodEndDate && sub?.priceAmountCents > 0
    )
    if (paying) {
      return paying
    }
  }

  if (subscriptions?.length) {
    return subscriptions[0]
  } else return null
}

export const useIsFreeTrial = () => {
  const userCanEdit = useAvailableFeatures()?.user?.editTree
  const subscription = useGetSubscription()
  const isFreeTrial = Boolean(
    !subscription?.priceAmountCents &&
      subscription?.currentSubscriptionPeriodEndDate &&
      userCanEdit
  )

  return isFreeTrial
}

export const useArticleTemplates = () => {
  const dispatch = useDispatch()
  const isExportMode = useIsAlphaFeatureUser()?.exportMode
  const articleTemplates = useSelector(selectArticleTemplates)

  useEffect(() => {
    if (!isExportMode) {
      if (
        articleTemplates.results?.length === 0 &&
        articleTemplates.state === null
      ) {
        dispatch(fetchArticleTemplates())
      } else if (
        articleTemplates.state !== 'loading' &&
        articleTemplates.lastRecievedAtMs &&
        articleTemplates.lastRecievedAtMs < Date.now() - 1000 * 60 * 60 * 6
      ) {
        dispatch(fetchArticleTemplates())
      }
    }
  }, [dispatch, articleTemplates, isExportMode])

  return articleTemplates.results || []
}

export const useIsPublic = () => {
  const location = useLocation() // This hook returns the current location object, which contains the current URL among other things.

  // Check if the pathname includes '/share/' or '/public/'
  const isPublic =
    location.pathname.includes('/share/') ||
    location.pathname.includes('/public/')

  return isPublic
}

export const useLinkedPagePageUrlSegments = () => {
  const shortUrlParams = useRouteMatch(`/:slug/:pathSegment/:linkedPageId`)
  const longUrlParams = useRouteMatch(
    `/:slug/:pathSegment/:linkedPageId/:currentTab`
  )

  if (longUrlParams) {
    const {
      params: { slug, pathSegment, linkedPageId, currentTab },
    } = longUrlParams

    const pageType = INSTANCE_TYPE_FOR_PATH_SEGMENT[pathSegment]

    return {
      slug,
      pageType,
      linkedPageId,
      currentTab,
      pathSegment,
    }
  } else {
    const {
      params: { slug, pathSegment, linkedPageId },
    } = shortUrlParams

    const pageType = INSTANCE_TYPE_FOR_PATH_SEGMENT[pathSegment]

    return {
      slug,
      pageType,
      linkedPageId,
      pathSegment,
      currentTab: '',
    }
  }
}
